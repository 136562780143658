<template>
  <div
    ref="sliderViewRef"
    class="slider-view"
    @click="handleSliderClick"
  >
    <div class="score-slider">
      <img
        v-if="type === 'red'"
        ref="sliderScaleRef"
        alt="slider"
        class="score-slider__scale"
        src="@shared/assets/img/score-slider-scale_red.svg"
      />
      <img
        v-else-if="type === 'green'"
        ref="sliderScaleRef"
        alt="slider"
        class="score-slider__scale"
        src="@shared/assets/img/score-slider-scale_green.svg"
      />
      <button
        ref="sliderKnobRef"
        :data-qa="dataQA"
        :data-qa-input="dataQAInput"
        :data-qa-output="dataQAOutput"
        :style="`left:${knobLeftPosition}%;`"
        aria-label="slider knob"
        class="score-slider__knob score-slider__knob--hidden"
        role="button"
        type="button"
        @touchmove.prevent
      >
        <img
          ref="sliderKnobImageRef"
          alt="slider knob"
          class="score-slider__knob-img score-slider__knob-img--none"
          src="@shared/assets/img/score-slider-knob.svg"
          @mousedown.prevent="slideStartHandler"
          @touchstart.prevent="slideStartHandler"
        />
        <span
          ref="sliderKnobLabelRef"
          class="score-slider__label"
          @mousedown.prevent="slideStartHandler"
          @touchstart.prevent="slideStartHandler"
          >{{ sliderLabel }}</span
        >
      </button>
    </div>
    <div class="hints">
      <div
        class="slider-view__hint"
        v-html="minText"
      />
      <div
        class="slider-view__hint"
        v-html="maxText"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

const props = defineProps({
  maxScore: {
    type: Number,
    default: 0,
  },
  score: {
    type: Number,
    default: 0,
  },
  label: {
    type: String,
    default: '',
  },
  dataQA: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'red',
  },
  minText: {
    type: String,
    default: '',
  },
  maxText: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['tap', 'change'])

const sliderViewRef = ref(null)
const sliderScaleRef = ref(null)
const sliderKnobRef = ref(null)
const sliderKnobImageRef = ref(null)
const sliderKnobLabelRef = ref(null)
const sliderLabel = ref(props.label || '?')
const sliderWidth = ref(0)
const knobLeftPosition = ref(0)
const previousKnobLeftPosition = ref(0)
const startKnobLeftPosition = ref(0)
const knobElementWidth = ref(0)
const startPageX = ref(0)
const dataQAOutput = ref(0)
const dataQAInput = ref(0)
let dataQAInputObserver = null

const getSafeScore = computed(
  () => (props.score != null && props.score >= 0 && props.score) || 0,
)
const getSafeMaxScore = computed(
  () => (props.maxScore != null && props.maxScore >= 0 && props.maxScore) || 0,
)
const getScoreSize = computed(() =>
  getSafeMaxScore.value > 0
    ? (100 - knobElementWidth.value) / getSafeMaxScore.value
    : (100 - knobElementWidth.value) / 100,
)

const slideStartHandler = (e) => {
  if (
    e.target !== sliderKnobImageRef.value &&
    e.target !== sliderKnobLabelRef.value
  ) {
    return
  }

  sliderLabel.value = dataQAOutput.value
  emit('tap', { value: dataQAOutput.value })

  sliderWidth.value = sliderViewRef.value.offsetWidth
  knobElementWidth.value = Math.floor(
    (sliderKnobImageRef.value.offsetWidth / sliderWidth.value) * 100,
  )
  startPageX.value = e.pageX || e.touches[0].pageX
  startKnobLeftPosition.value = knobLeftPosition.value
  previousKnobLeftPosition.value = knobLeftPosition.value

  document.addEventListener('mousemove', slideMoveHandler)
  document.addEventListener('touchmove', slideMoveHandler)

  document.addEventListener('mouseup', slideEndHandler)
  document.addEventListener('touchend', slideEndHandler)
}

const slideMoveHandler = (e) => {
  const currentPageX = e.pageX || e.touches[0].pageX
  const deltaX =
    Math.abs((currentPageX - startPageX.value) / sliderWidth.value) * 100

  if (previousKnobLeftPosition.value !== knobLeftPosition.value) {
    previousKnobLeftPosition.value = knobLeftPosition.value

    dataQAOutput.value = Math.ceil(knobLeftPosition.value / getScoreSize.value)
    emit('change', { value: dataQAOutput.value })
    sliderLabel.value = dataQAOutput.value
  }

  if (currentPageX > startPageX.value) {
    const nextKnobLeftPosition =
      (startKnobLeftPosition.value + adjustDeltaX(deltaX)).toFixed(2) * 1

    knobLeftPosition.value =
      nextKnobLeftPosition >= 100 - knobElementWidth.value
        ? 100 - knobElementWidth.value
        : nextKnobLeftPosition
  } else if (currentPageX < startPageX.value) {
    const nextKnobLeftPosition =
      (startKnobLeftPosition.value - adjustDeltaX(deltaX)).toFixed(2) * 1

    knobLeftPosition.value =
      nextKnobLeftPosition <= 0 ? 0 : nextKnobLeftPosition
  }
}

const slideEndHandler = () => {
  if (previousKnobLeftPosition.value !== knobLeftPosition.value) {
    previousKnobLeftPosition.value = knobLeftPosition.value

    dataQAOutput.value = Math.ceil(knobLeftPosition.value / getScoreSize.value)
    emit('change', { value: dataQAOutput.value })
    sliderLabel.value = dataQAOutput.value
  }

  document.removeEventListener('mousemove', slideMoveHandler)
  document.removeEventListener('touchmove', slideMoveHandler)

  document.removeEventListener('mouseup', slideEndHandler)
  document.removeEventListener('touchend', slideEndHandler)
}

const adjustDeltaX = (deltaX) => {
  const scoreSize = getScoreSize.value
  if (deltaX > scoreSize) {
    return Math.floor(deltaX / scoreSize) * scoreSize
  }

  return 0
}

const handledataQAInputMutation = (mutation) => {
  if (
    mutation.type !== 'attributes' ||
    mutation.attributeName !== 'data-qa-input'
  ) {
    return
  }

  const nextScoreValue =
    mutation.target.getAttribute(mutation.attributeName) * 1
  let currentScoreValue = dataQAOutput.value * 1

  if (
    nextScoreValue < 0 ||
    nextScoreValue > props.maxScore ||
    nextScoreValue === currentScoreValue
  ) {
    return
  }

  const clientRect = mutation.target.getBoundingClientRect()
  let clientRectX = clientRect.x

  sliderKnobImageRef.value.dispatchEvent(
    new MouseEvent('mousedown', {
      bubbles: true,
      clientX: clientRectX,
      clientY: clientRect.y,
    }),
  )

  while (currentScoreValue !== nextScoreValue) {
    clientRectX =
      currentScoreValue > nextScoreValue ? clientRectX - 5 : clientRectX + 5

    sliderKnobImageRef.value.dispatchEvent(
      new MouseEvent('mousemove', {
        bubbles: true,
        clientX: clientRectX,
        clientY: clientRect.y,
      }),
    )

    currentScoreValue = dataQAOutput.value
  }

  sliderKnobImageRef.value.dispatchEvent(
    new MouseEvent('mouseup', {
      bubbles: true,
      clientX: clientRectX,
      clientY: clientRect.y,
    }),
  )
}

const handleSliderClick = (e) => {
  const rect = sliderViewRef.value.getBoundingClientRect()
  const clickX = e.clientX - rect.left
  const clickPosition = (clickX / sliderWidth.value) * 100

  knobLeftPosition.value = adjustDeltaX(clickPosition).toFixed(2) * 1

  dataQAOutput.value = Math.ceil(knobLeftPosition.value / getScoreSize.value)
  emit('change', { value: dataQAOutput.value })
  sliderLabel.value = dataQAOutput.value
}

onMounted(() => {
  const sliderScaleLoadHandler = () => {
    sliderWidth.value = sliderViewRef.value.offsetWidth

    dataQAOutput.value = getSafeScore.value
    dataQAInput.value = getSafeScore.value

    sliderKnobRef.value.classList.remove('score-slider__knob--hidden')
    sliderKnobImageRef.value.classList.remove('score-slider__knob-img--none')

    knobElementWidth.value = Math.floor(
      (sliderKnobImageRef.value.offsetWidth / sliderWidth.value) * 100,
    )
    knobLeftPosition.value = getScoreSize.value * getSafeScore.value

    dataQAInputObserver = new MutationObserver((mutations) => {
      mutations.forEach(handledataQAInputMutation)
    })
    dataQAInputObserver.observe(sliderKnobRef.value, {
      attributes: true,
    })
  }

  sliderScaleRef.value.onload = () => {
    sliderScaleRef.value.onload = null
    if (sliderKnobImageRef.value.onload == null) {
      sliderScaleLoadHandler()
    }
  }

  sliderKnobImageRef.value.onload = () => {
    sliderKnobImageRef.value.onload = null
    if (sliderScaleRef.value.onload == null) {
      sliderScaleLoadHandler()
    }
  }
})

onBeforeUnmount(() => {
  dataQAInputObserver.disconnect()
})
</script>

<style lang="scss">
.slider-view {
  margin: 0 auto;
  width: 80%;
  padding: 100px 0 48px 0;

  @media (max-width: $breakpoint-mobile) {
    width: 100%;
    padding: 68px 0 16px 0;
  }
}

.score-slider {
  width: 100%;
  min-height: 26px;
  position: relative;

  &__scale {
    width: 100%;
    display: block;
  }

  &__label {
    position: absolute;
    left: 50%;
    top: -52px;
    transform: translateX(-50%);
    padding: 0 8px;
    background-color: $white;
    box-shadow: 0 4px 8px rgba(28, 62, 126, 0.2);
    border-radius: 8px;
    color: $midnight;
    text-align: center;
    cursor: pointer;

    @include kaia-typography-h5($font-weight-semi-bold);
  }

  &__knob {
    position: absolute;
    top: 0;
    bottom: 0;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    padding: 0;
    border-radius: 50%;
    box-shadow:
      0 8px 8px rgba(10, 28, 64, 0.1),
      0 8px 16px rgba(10, 28, 64, 0.1);

    &--hidden {
      visibility: hidden;
    }

    &-img {
      cursor: pointer;
      height: 100%;

      &--none {
        display: none;
      }
    }
  }
}

.hints {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  @include kaia-typography-p2($font-weight-normal);

  @media (max-width: $breakpoint-mobile) {
    @include kaia-typography-p1($font-weight-normal);
  }
}
</style>
