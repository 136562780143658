import { computed, ref } from 'vue'
import { defineStore } from 'pinia'

const useAuthStore = defineStore(
  'auth',
  () => {
    const accessToken = ref(/** @type { string|null} */ null)
    const client = ref(/** @type { string|null} */ null)
    const guid = ref(/** @type { string|null} */ null)
    const tokenType = ref(/** @type { string|null} */ null)
    const refreshToken = ref(/** @type { string|null} */ null)
    const hostClientAppId = ref(/** @type { string|null} */ null)
    const hostClientVersion = ref(/** @type { string|null} */ null)

    const isAuthenticated = computed(() => !!accessToken.value)

    const invalidateTokens = () => {
      accessToken.value = null
      refreshToken.value = null
    }

    return {
      accessToken,
      client,
      guid,
      tokenType,
      refreshToken,
      hostClientAppId,
      hostClientVersion,
      isAuthenticated,
      invalidateTokens,
    }
  },
  {
    persist: {
      storage: sessionStorage,
    },
  },
)

export default useAuthStore
