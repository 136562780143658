// https://html.spec.whatwg.org/multipage/webstorage.html#dom-localstorage

class Storage {
  constructor() {
    this.storage = {}
  }

  getItem(key = '') {
    return typeof this.storage[key] === 'undefined' ? null : this.storage[key]
  }

  setItem(key = '', value = '') {
    this.storage[key] = String(value)
  }

  removeItem(key = '') {
    if (typeof this.storage[key] !== 'undefined') {
      delete this.storage[key]
    }
  }

  clear() {
    this.storage = {}
  }

  key(index) {
    const keys = Object.keys(this.storage)
    if (typeof index !== 'number' || index >= keys.length) {
      return null
    }

    return keys[index]
  }
}

class WebStorage {
  constructor(storageFactory) {
    this.instance = storageFactory()
  }

  getItem(key) {
    return this.instance.storage.getItem(key)
  }

  setItem(key, value) {
    try {
      this.instance.storage.setItem(key, value)
    } catch (e) {
      // Log QuotaExceededError if the space quota has been exceeded;
      console.error('WebStorageFactory#setItem', e.message)
    }
  }

  removeItem(key) {
    this.instance.storage.removeItem(key)
  }

  clear() {
    this.instance.storage.clear()
  }

  key(index) {
    this.instance.storage.key(index)
  }
}

class WebStorageFactory {
  constructor(context = {}, storageType) {
    const storage = new Storage()

    try {
      context[storageType]

      return new WebStorage(() => ({
        get storage() {
          try {
            return context[storageType]
          } catch (e) {
            // Log QuotaExceededError if WebStorage is not available;
            console.error(`${storageType}'#WebStorageFactory:getter`, e.message)
            return storage
          }
        },
      }))
    } catch (e) {
      // Log QuotaExceededError if WebStorage is not available;
      console.error(`${storageType}'#WebStorageFactory:constructor`, e.message)
      return storage
    }
  }
}

export const localStorage = new WebStorageFactory(window, 'localStorage')
export const sessionStorage = new WebStorageFactory(window, 'sessionStorage')
