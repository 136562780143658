<template>
  <div class="quiz-content__inner welcome-theme self-test">
    <div class="self-test__layout">
      <div class="kds-main-wrapper">
        <p
          class="kds-typography-display-small kds-typography-center"
          v-html="$t('b2b_self_test_get_started')"
        />

        <p
          class="kds-typography-paragraph-large kds-typography-center"
          v-html="$t('b2b_self_test_get_started_content')"
        />

        <div class="figure">
          <LottieAnimationContainer :json-config-path="jsonConfigPath" />
        </div>

        <BaseButton
          :text="$t('b2b_get_started_btn_text')"
          data-qa="submit"
          @click="handleNextEvent"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseButton from '@shared/components/BaseButton.vue'
import LottieAnimationContainer from '@shared/components/LottieAnimationContainer.vue'
import env from '@shared/env.js'

defineProps({
  handleNextEvent: {
    type: Function,
    default: () => {},
  },
})

const jsonConfigPath = `${env.VITE_PUBLIC_PATH}static/lottie-self-test-welcome-media.json`
</script>

<style lang="scss" scoped>
@use '@shared/modules/assessment/views/css/SelfTest.scss' as *;
.welcome-theme {
  background-color: $light-blue;

  .figure {
    display: none;
    margin: 0 auto;
    width: 66%;
  }

  @media (max-width: $breakpoint-tablet) {
    background-color: $sky;

    .figure {
      display: block;
    }
  }
}
</style>
