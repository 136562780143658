<template>
  <button
    ref="button"
    :type="type"
    class="base-button"
    :class="{
      [variant]: !!variant,
      [size]: !!size,
      inverted,
      icon,
      loading,
      success,
    }"
    :disabled="disabled || loading || success"
    :data-qa="dataQa"
    @click="handleClick"
  >
    <div
      v-if="hasSlot['prepend'] && !icon && !loading && !success"
      class="base-button__prepend"
    >
      <slot name="prepend" />
    </div>
    <span class="center">
      <slot>
        {{ text }}
      </slot>
    </span>
    <CheckIcon
      v-if="success"
      class="success-icon"
    />
    <div
      v-if="hasSlot['append'] && !icon && !loading && !success"
      class="base-button__append"
    >
      <slot name="append" />
    </div>
  </button>
</template>

<script setup>
import { ref } from 'vue'
import useVueSlots from '@shared/composables/useVueSlots.js'
import CheckIcon from '@shared/assets/icon/CheckIcon.vue'

const { hasSlot } = useVueSlots(['prepend', 'append', 'default'])

const button = ref()
const emit = defineEmits(['click'])

const props = defineProps({
  type: {
    type: String,
    default: 'button',
  },
  icon: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: '',
  },
  // INFO: Inverted styles are not yet implemented for all variants.
  inverted: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String,
    default: 'primary',
    validator: (val) =>
      ['primary', 'emphasized', 'destructive', 'outlined', 'ghost'].includes(
        val,
      ),
  },
  size: {
    type: String,
    default: 'regular',
    validator: (val) => ['regular', 'small'].includes(val),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  success: {
    type: Boolean,
    default: false,
  },
  dataQa: {
    type: String,
    default: '',
  },
})

const handleClick = (e) => {
  e.preventDefault()
  if (props.loading || props.disabled || props.success) return

  emit('click', e)
}

defineExpose({ button })
</script>

<style lang="scss">
@use 'sass:map';

:root {
  // TODO
  --base-button--padding: 16px 32px;
  --base-button--border-radius: 100px;
  --base-button--border-style: none;
  --base-button--border-width: 0;
  --base-button--border-color: 0;
  --base-button--outline-width: 1.5px;
  --base-button--height: auto;
  --base-button--opacity: 1;
  --base-button--background-color: #{map.get(
      $button-background,
      interactive-strong
    )};
  --base-button--color: #{$white};
  --base-button--font-size: 17px;
  --base-button--line-height: 22.1px;
  --base-button--cursor: pointer;
  --base-button--visibility: visible;
  --base-button--loading-background-color-before: conic-gradient(
    transparent,
    #{map.get($button-background, interactive-strong)}
  );
  --base-button--loading-background-color-after: #{map.get(
      $button-background,
      interactive-strong
    )};
}

/*
  INFO: Mixin for button text color based on variant
*/
@mixin button-text($variant) {
  --base-button--color: #{map.get($button-text, 'on-interactive-#{$variant}')};
}

/*
  INFO: Mixin for button background and text color based on variant
    Also includes hover and active states
*/
@mixin button-styles($variant) {
  --base-button--background-color: #{map.get(
      $button-background,
      'interactive-#{$variant}'
    )};

  &:hover {
    --base-button--background-color: #{map.get(
        $button-background,
        'interactive-#{$variant}-hover'
      )};
  }

  &:focus {
    --base-button--background-color: #{map.get(
        $button-background,
        'interactive-#{$variant}-hover'
      )};
  }

  &:active {
    --base-button--background-color: #{map.get(
        $button-background,
        'interactive-#{$variant}-active'
      )};
  }
}

.base-button {
  @include flex-container(row, center, sm, center);
  position: relative;
  width: 100%;
  background-color: var(--base-button--background-color);
  color: var(--base-button--color);
  border-radius: var(--base-button--border-radius);
  border-width: var(--base-button--border-width);
  border-color: var(--base-button--border-color);
  border-style: var(--base-button--border-style);
  padding: var(--base-button--padding);
  height: var(--base-button--height);
  font-size: var(--base-button--font-size);
  line-height: var(--base-button--line-height);
  cursor: var(--base-button--cursor);
  opacity: var(--base-button--opacity);
  transition: background 0.25s ease;

  .success-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  > div {
    @include flex-container(row);
  }

  .center {
    visibility: var(--base-button--visibility);
  }

  &.small {
    --base-button--padding: 8px 16px;
  }

  &:focus {
    --base-button--border-color: #{map.get(
        $button-border-color,
        on-interactive-focus
      )};
    outline-color: #{map.get($button-border-color, on-interactive-focus)};
    outline-style: solid;
    outline-width: var(--base-button--outline-width);

    -webkit-box-shadow: inset 0 0 0 1.5px
      #{map.get($button-border-color, on-interactive-focus)};
    -moz-box-shadow: inset 0 0 0 1.5px
      #{map.get($button-border-color, on-interactive-focus)};
    box-shadow: inset 0 0 0 1.5px
      #{map.get($button-border-color, on-interactive-focus)};
  }

  &.icon {
    width: 54px;
    --base-button--height: 54px;
    --base-button--border-radius: 50%;
    --base-button--padding: 16px;

    .center {
      @include flex-container(column, center);
    }

    &.small {
      width: 34px;
      --base-button--height: 34px;
    }
  }

  &:disabled {
    --base-button--opacity: 0.9;
    --base-button--background-color: #{map.get(
        $button-background,
        interactive-disabled
      )};

    --base-button--color: #{map.get($button-text, on-interactive-disabled)};
    --base-button--cursor: not-allowed;

    &.outlined {
      --base-button--border-style: solid;
      --base-button--border-width: 1.5px;
      --base-button--border-color: #{$background-interactive-subtle-active};
      --base-button--background-color: #{$white};
      --base-button--loading-background-color-after: #{$white};
    }

    &.loading {
      --base-button--cursor: not-allowed;
      --base-button--background-color: #{map.get(
          $button-background,
          interactive-disabled
        )};

      --base-button--loading-background-color-after: #{map.get(
          $button-background,
          interactive-disabled
        )};

      &.outlined {
        --base-button--border-style: solid;
        --base-button--border-width: 1.5px;
        --base-button--border-color: #{$background-interactive-subtle-active};
        --base-button--background-color: #{$white};
        --base-button--loading-background-color-after: #{$white};
      }
    }
  }

  &.success {
    --base-button--color: #{$text-primary};
    position: relative;
    --base-button--visibility: hidden;
  }

  &.loading {
    --base-button--visibility: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background: var(--base-button--loading-background-color-before);
      animation: spin 1s linear infinite;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--base-button--loading-background-color-after);
    }
  }

  &:not([disabled]) {
    &.primary {
      @include button-styles(strong);
    }

    &.emphasized {
      @include button-styles(accent);
    }

    &.destructive {
      @include button-styles(destructive);
    }

    &.outlined {
      --base-button--background-color: 0;
      --base-button--border-style: solid;
      --base-button--border-width: 1.5px;

      --base-button--border-color: #{$background-interactive-subtle-active};

      @include button-text(subtle);
      @include button-styles(subtle);

      &.inverted {
        @include button-text(subtle);
        @include button-styles(subtle-inverse);
      }

      &:focus {
        --base-button--outline-width: 0;
      }

      &:focus {
        --base-button--border-color: #{map.get(
            $button-border-color,
            on-interactive-focus
          )};
      }
    }

    &.ghost {
      @include button-text(subtle);
      @include button-styles(subtle);

      &.inverted {
        @include button-text(subtle);
        @include button-styles(subtle-inverse);
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
