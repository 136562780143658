<template>
  <div
    class="date-of-birth"
    data-qa="date-of-birth"
  >
    <label
      class="date-of-birth__label"
      for="date-of-birth"
    >
      {{ label || $t('date-of-birth__label') }}
    </label>
    <div
      class="date-of-birth__inputs"
      name="date-of-birth"
    >
      <div
        v-for="(key, index) in Object.keys(config)"
        :key="index"
        class="date-of-birth__inputs--input"
      >
        <TextField
          ref="inputRefs"
          v-bind="{
            ...config[key],
            ...$attrs,
          }"
          v-model="model[key]"
          :class="key"
          :name="key"
          :show-errors="false"
          :label="$t(`date-of-birth__label--${key}`)"
          :has-errors="!!errorsObj?.[key]?.length"
          :data-qa="`date-of-birth-${key}`"
          @blur="handleBlur(key)"
          @focus="handleFocus(key)"
          @input="handleInput($event, key, index)"
          @keypress="isNumber($event, key)"
        />
      </div>
    </div>
    <div
      v-if="firstErrorKey"
      class="date-of-birth-errors"
    >
      <DangerIcon class="error-icon" />
      <span>
        {{ $t(firstErrorKey) }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue'
import { isNumeric } from '@shared/utils'
import TextField from './TextField.vue'
import DangerIcon from '@shared/assets/icon/DangerIcon.vue'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  lang: {
    type: String,
    default: 'en-US',
  },
  label: {
    type: String,
    default: '',
  },
  errors: {
    type: Array,
    default: () => [],
  },
})

const focusedKey = ref('')
const inputRefs = ref([])
const emit = defineEmits([
  'update:modelValue',
  'set-errors',
  'blur',
  'input',
  'focus',
])

defineOptions({
  inheritAttrs: false,
})

/**
 * Groups errors into object
 * [['day', 'error_1'] ['day', 'error_2'], ['month', 'error_3']] => { day: ['error_1', 'error_2'], month: ['error_3']}
 *
 * @returns { [string:key]: [string,string] }
 */
const errorsObj = computed(() => {
  return props.errors.reduce((accum, [key, value]) => {
    accum[key] = accum[key] ? [...accum[key], value] : [value]
    return accum
  }, {})
})

const getObjByLang = (year, month, day) => {
  switch (props.lang) {
    case 'de-DE': {
      return {
        day,
        month,
        year,
      }
    }
    default: {
      return {
        month,
        day,
        year,
      }
    }
  }
}

const config = computed(() => {
  const day = {
    placeholder: 'DD',
    type: 'text',
    inputmode: 'numeric',
    autocomplete: 'bday-day',
  }

  const month = {
    placeholder: 'MM',
    type: 'text',
    inputmode: 'numeric',
    autocomplete: 'bday-month',
  }

  const year = {
    placeholder: 'YYYY',
    type: 'text',
    inputmode: 'numeric',
    autocomplete: 'bday-year',
  }

  return getObjByLang(year, month, day)
})

const blurredKeys = ref([])

const model = computed(() => {
  const [year = '', month = '', day = ''] = props.modelValue.split('-')
  return getObjByLang(year, month, day)
})

const updateModel = () => {
  if (!model.value.year && !model.value.month && !model.value.day) {
    emit('update:modelValue', '')
    return
  }

  emit(
    'update:modelValue',
    `${model.value.year ?? ''}-${model.value.month ?? ''}-${
      model.value.day ?? ''
    }`,
  )
}

/**
 * Returns the first error found in errorsByKey object or dateErrors array
 */
const firstErrorKey = computed(() => {
  const rearrangedErrors = getObjByLang(
    errorsObj.value.year ?? [],
    errorsObj.value.month ?? [],
    errorsObj.value.day ?? [],
  )

  for (const key in rearrangedErrors) {
    if (rearrangedErrors[key].length > 0) {
      return rearrangedErrors[key][0]
    }
  }

  if (errorsObj.value?.date?.length) {
    return errorsObj.value?.date?.[0]
  }

  return null
})

const handleInput = (e, key, index) => {
  const { value } = e.target
  if (!isNumeric(value)) {
    inputRefs.value[index].input = ''
    model.value[key] = ''
  }

  const isLastIndex = index === 2
  const nextInput = inputRefs.value?.[index + 1]?.input

  if (key === 'day' || key === 'month') {
    const maxValue = key === 'day' ? 3 : 1

    if ((value.length === 2 || value > maxValue) && !isLastIndex) {
      appendZero(key)
      nextInput.focus()
    }
  }

  emit('input', {
    blurredKeys: blurredKeys.value,
    focusedKey: focusedKey.value,
  })
  updateModel()
}

const handleFocus = (key) => {
  focusedKey.value = key
  nextTick(() => emit('focus', focusedKey.value))
}
const handleBlur = (key) => {
  focusedKey.value = ''
  const len = model?.value?.[key]?.length

  if (!blurredKeys.value.includes(key)) {
    blurredKeys.value.push(key)
  }

  if (key !== 'year') {
    if (len === 1) {
      appendZero(key)
    }
  }

  updateModel()
  nextTick(() => {
    emit('blur', {
      blurredKeys: blurredKeys.value,
      focusedKey: focusedKey.value,
    })
  })
}

const isNumber = (event, key) => {
  const { key: keyPressed, target } = event

  if (!isNumeric(keyPressed)) {
    event.preventDefault()
  }

  const { selectionEnd, selectionStart } = target
  const selected = selectionEnd - selectionStart !== 0

  const len = model.value?.[key]?.length
  const maxLen = key === 'year' ? 4 : 2

  if (len === maxLen && !selected) event.preventDefault()
}

const appendZero = (key) => {
  const len = model.value[key].toString().length

  if (len > 1) {
    return (model.value[key] = `${model.value[key]}`)
  }

  return (model.value[key] = `0${model.value[key]}`)
}

onMounted(() => {
  updateModel()
})
</script>

<style lang="scss" scoped>
.date-of-birth {
  @include flex-container(column, center, xs);

  &__label {
    @include kaia-typography-p2($font-weight-semi-bold);
    @include margin(xs, bottom);
  }

  &__inputs {
    @include flex-container(row, flex-start, sm);

    &--input {
      width: 100%;
    }

    @include media-up(md) {
      &--input {
        width: 91px;
      }
    }
  }
}

.date-of-birth-errors {
  @include flex-container(row, flex-start, sm, center);

  span {
    @include margin(0);
    @include padding(0);
    font-size: 12px;
    color: $danger;
  }
}
</style>
