<template>
  <ResponsiveLayout>
    <form class="kds-main-wrapper">
      <h1>Init mocked uhchub handshake</h1>
      <TextField
        ref="firstNameRef"
        v-bind="fieldAttrs.firstName"
        autocomplete="given-name"
        label="First Name"
      />
      <TextField
        v-bind="fieldAttrs.lastName"
        autocomplete="family-name"
        label="Last Name"
      />
      <TextField
        v-bind="fieldAttrs.email"
        type="email"
        autocomplete="email"
        label="Email"
      />
      <DateOfBirthField v-bind="fieldAttrs.dob" />
      <TextField
        v-bind="fieldAttrs.memberID"
        label="Member ID"
        hint="This is the users identifier within the group"
      />
      <TextField
        v-bind="fieldAttrs.groupNumber"
        label="Group Number"
        hint="This is the user group the user belongs to - this usually maps to our concept of corporates"
      />
      <BaseButton
        v-bind="submitAttrs"
        text="Go to Kaia"
      />
    </form>
  </ResponsiveLayout>
</template>

<script setup>
import BaseButton from '@shared/components/BaseButton.vue'
import DateOfBirthField from '@shared/components/form/DateOfBirthField.vue'
import TextField from '@shared/components/form/TextField.vue'
import useForm from '@shared/composables/useForm'
import { ref } from 'vue'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'
// TODO dropdown for env selection (localhost + stage api, stage, prod)

const handleSubmitSuccess = () => {
  console.info(form)
  const [year, month, day] = form.value.dob.split('-')
  const url = new URL('https://sso.stage.kaiahealth.com/saml-sp/optum/mocked')
  Object.entries({
    FirstName: form.value.firstName,
    LastName: form.value.lastName,
    Email: form.value.email,
    DOB: year && month && day ? `${month}/${day}/${year}` : null,
    MemberID: form.value.memberID,
    GroupNumber: form.value.groupNumber,
  })
    .filter(([, value]) => !!value)
    .forEach(([key, value]) => url.searchParams.append(key, value))
  url.searchParams.append('redirect_host', window.location.hostname)
  url.searchParams.append('redirect_port', window.location.port)
  url.searchParams.append(
    'redirect_http',
    (!window.location.protocol.startsWith('https')).toString(),
  )
  console.info(`redirecting to ${url.toString()}`)
  window.location = url.toString()
}

const form = ref({
  firstName: '',
  lastName: '',
  email: '',
  dob: '',
  memberID: '',
  groupNumber: '',
})

const { fieldAttrs, submitAttrs } = useForm(
  form,
  ref({
    firstName: { validations: ['required'] },
    lastName: { validations: ['required'] },
    email: { validations: ['required', 'email'] },
    dob: { validations: ['dob'] },
    memberID: { validations: ['required'] },
    groupNumber: { validations: ['required'] },
  }),
  {
    onSubmit: () => {},
    onSuccess: () => handleSubmitSuccess(),
    onError: (error) => console.error(error),
  },
)
</script>

<style lang="scss"></style>
