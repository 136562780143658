<template>
  <div class="quiz-content__inner">
    <QuizNavHeader
      :stage="currentQuestionnaire"
      :stage-count="questionnaireCount"
      :stage-progress="100"
      :title="$t('b2b_quiz_cat_completed_title')"
      :back-button="false"
    />

    <QuizContentContainer>
      <div class="kds-main-wrapper">
        <img
          class="icon"
          src="@shared/assets/img/b2b/union-icon.svg"
        />
        <p class="kds-typography-display-small kds-typography-center">
          {{ $t(catSummary.summary.title) }}
        </p>

        <div class="score">
          <span :class="catSummary.summary.group">{{ catSummary.score }}</span>
          <span class="score__max"> {{ $t('b2b_quiz_cat_of') }} 40</span>
          <p class="graphic">
            <img src="@shared/assets/img/cat-score.svg" />
            <span>{{ $t('b2b_quiz_cat_legend_0') }}</span>
            <span>{{ $t('b2b_quiz_cat_legend_40') }}</span>
          </p>
        </div>

        <div
          v-for="(item, index) in catSummary.summary.recommendations"
          :key="index"
        >
          <p class="kds-typography-title-medium">{{ $t(item.title) }}</p>
          <p class="kds-typography-paragraph-large">
            {{ $t(item.recommendation) }}
          </p>
        </div>
        <BaseButton
          data-qa="submit"
          @click="nextSurvey"
        >
          {{ $t('generic_action_continue') }}
        </BaseButton>
      </div>
    </QuizContentContainer>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import BaseButton from '@shared/components/BaseButton.vue'
import QuizNavHeader from '@shared/components/Quiz/QuizNavHeader.vue'
import QuizContentContainer from '@shared/components/Quiz/QuizContentContainer.vue'

const router = useRouter()
const store = useStore()

const currentQuestionnaire = computed(
  () => store.getters['assessment/currentQuestionnaire'],
)
const questionnaireCount = computed(
  () => store.getters['assessment/questionnaireCount'],
)
const catSummary = computed(() => store.getters['assessment/catSummary'])

const nextSurvey = () => {
  store.dispatch('assessment/completeQuestionnaire', { router })
}
</script>

<style lang="scss" scoped>
.icon {
  display: block;
  margin: 0 auto 30px;
}

.score {
  font-size: 24px;
  text-align: center;

  .low {
    color: $green;
  }
  .medium {
    color: $blue;
  }
  .high {
    color: $yellow;
  }
  .very-high {
    color: $red_pain;
  }
  .graphic {
    img {
      max-width: 100%;
    }
    span {
      text-align: left;
      display: inline-block;
      font-size: 14px;
      width: 50%;
    }
    span:last-child {
      text-align: right;
    }
  }

  &__max {
    margin-left: 6px;
  }
}
</style>
