<template>
  <div class="dots-loader">
    <span class="dots-loader__item" />
  </div>
</template>

<script>
export default {
  name: 'DotsLoader',
}
</script>

<style lang="scss">
@keyframes bg-pulse-1 {
  0% {
    opacity: 0.35;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.35;
  }
}

@keyframes bg-pulse-2 {
  0% {
    opacity: 0.35;
  }

  40% {
    opacity: 0.55;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0.35;
  }
}

@keyframes bg-pulse-3 {
  0% {
    opacity: 0.35;
  }

  70% {
    opacity: 0.55;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0.35;
  }
}

.dots-loader {
  font-size: 0;

  &::before,
  &::after {
    content: '';
  }

  &__item,
  &::before,
  &::after {
    opacity: 0.35;
    display: inline-block;
    background-color: $text-primary;
    border-radius: 12px;
    margin-right: 10px;
    width: 24px;
    height: 24px;

    animation: bg-pulse-2 1.2s linear infinite;
  }

  &::before {
    animation-name: bg-pulse-1;
  }

  &::after {
    animation-name: bg-pulse-3;
  }
}
</style>
