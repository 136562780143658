<template>
  <div :class="quizNavHeaderCSSClass">
    <div class="quiz-nav-header">
      <a
        v-if="backButton"
        class="btn-back"
        @click.prevent="$emit('btnBackClicked')"
      />
      <a
        v-else-if="infoText"
        class="btn-back btn-back--hidden"
      />

      <h1
        class="quiz-nav-header__title"
        v-html="title"
      />

      <a
        v-if="infoText"
        class="btn-info"
        @click.prevent="showInfoPopup = true"
      />
      <a
        v-else-if="backButton"
        class="btn-back btn-back--hidden"
      />
    </div>

    <div class="quiz-nav-progress">
      <div
        :style="`width: ${fullProgress}%`"
        class="progress-bar"
      />
      <div
        class="progress-section progress-section--first progress-section--done"
      />
      <div
        v-for="(item, index) in stageSections"
        :key="index"
        :class="progressSectionCSSClass(index)"
        :style="`left: ${stageSectionOffsetLeft(index + 1)}%;`"
      />
    </div>

    <QuizInfoPopup
      v-if="showInfoPopup"
      :text="infoText"
      @close-popup="showInfoPopup = false"
    />
  </div>
</template>

<script>
import $mobileBridge from '@shared/VueMobileBridge'
import QuizInfoPopup from './QuizInfoPopup.vue'

export default {
  name: 'QuizNavHeader',
  components: { QuizInfoPopup },
  props: {
    title: {
      type: String,
      required: true,
    },
    stage: {
      type: Number,
      default: 0,
    },
    stageCount: {
      type: Number,
      default: 0,
    },
    stageProgress: {
      type: Number,
      default: 0,
    },
    backButton: {
      type: Boolean,
      default: true,
    },
    infoText: {
      type: String,
      default: null,
    },
  },
  emits: ['btnBackClicked'],
  data() {
    return {
      showInfoPopup: false,
      stageSections: new Array(this.stageCount),
    }
  },
  computed: {
    quizNavHeaderCSSClass() {
      return [
        'quiz-nav-header-wrap',
        $mobileBridge.hasMobileBridge() ? 'quiz-nav-header-wrap-hidden' : '',
      ]
    },
    fullProgress() {
      return Math.min(
        100,
        (this.stage * 100 + this.stageProgress) / this.stageCount,
      )
    },
  },
  unmounted() {
    $mobileBridge.reset()
  },
  mounted() {
    if ($mobileBridge.hasMobileBridge()) {
      $mobileBridge.setTitle(this.title)
      $mobileBridge.setProgress(
        this.stageCount,
        this.stage,
        Math.floor(this.stageProgress),
      )
      $mobileBridge.setInfo(this.infoText)
      if (this.backButton) {
        $mobileBridge.enableBackButton()
      } else {
        $mobileBridge.disableBackButton()
      }
    }
  },
  methods: {
    stageSectionOffsetLeft(stageIndex) {
      return stageIndex * (100 / this.stageCount)
    },
    progressSectionCSSClass(stageIndex) {
      return [
        'progress-section',
        this.stage >= stageIndex + 1 ? 'progress-section--done' : '',
      ]
    },
  },
}
</script>

<style scoped lang="scss">
.quiz-nav-header {
  $minHeight: 70px;
  $titleLineHeight: 26px;
  min-height: $minHeight;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .btn-back {
    background: url('../../assets/img/b2b/icon-arrow-right-w-secondary.svg')
      no-repeat center;
    height: $minHeight;
    width: $minHeight;
    transform: rotate(180deg);
    cursor: pointer;

    &--hidden {
      pointer-events: none;
      opacity: 0;
    }
  }

  .btn-info {
    background: url('../../assets/img/b2b/icon-info-ocean.svg') no-repeat center;
    height: $minHeight;
    width: $minHeight;
    cursor: pointer;

    &--hidden {
      pointer-events: none;
      opacity: 0;
    }
  }

  & &__title {
    padding-top: calc(($minHeight - $titleLineHeight) / 2);

    @include kaia-typography-p2($font-weight-semi-bold);
  }
}

.quiz-nav-header-wrap-hidden {
  display: none;
}

.quiz-nav-progress {
  display: block;
  position: relative;
  height: 8px;
  background-color: $gray-5;
  box-sizing: border-box;
  margin: 5px 20px 0;
  border-radius: 4px;

  @media (max-width: $breakpoint-tablet) {
    margin-top: -3px;
  }

  .progress-section {
    position: absolute;
    width: 8px;
    height: 8px;
    margin-left: -8px;
    background-color: $midnight;
    border-radius: 4px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -4px;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: $ocean;
    }

    &::after {
      left: 50%;
      top: 50%;
      margin-top: -2px;
      margin-left: -2px;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: $white;
    }

    &--first {
      margin-left: 0;
    }

    &--done {
      &::before {
        background-color: $midnight;
      }

      &::after {
        display: none;
      }
    }
  }

  .progress-bar {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: $ocean;
    border-radius: 4px;
  }
}
</style>
