<template>
  <!-- delayed sticky header -->
  <LandingStickyHeader
    :cta-btn-title="ctaBtnTitle"
    :handle-c-t-a-button-click="handleCTAButtonClick"
    :show-sticky-header="showStickyHeader"
    :is-german="isGerman"
  />

  <!-- inline header without button -->
  <LandingStickyHeader
    :show-inline-header="true"
    :is-german="isGerman"
  />

  <VptPrimerHero
    :cta-btn-title="ctaBtnTitle"
    :handle-c-t-a-button-click="handleCTAButtonClick"
    :receive-c-t-a-button-client-rect="receiveCTAButtonClientRect"
  />

  <InNetworkWith />

  <ReclaimingLife
    :cta-btn-title="$t('b2b__landing_page__generic__cta_btn__title')"
    :handle-c-t-a-button-click="handleCTAButtonClick"
  />

  <RealMembers />

  <Landing3Steps
    :cta-btn-title="$t('b2b__landing_page__generic__cta_btn__short_title')"
    :handle-c-t-a-button-click="handleCTAButtonClick"
  />

  <FaqModule />

  <Contacts />

  <LandingPageFooter
    :static-company-address="$t('static_contacts_address_US')"
    :corporate="corporate"
    :lang="lang"
  />
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'petite-vue-i18n'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import VptPrimerHero from '@us/app/components/uhc-vpt-primer/VptPrimerHero.vue'
import InNetworkWith from '@us/app/components/uhc-vpt-primer/InNetworkWith.vue'
import ReclaimingLife from '@shared/components/ReclaimingLife.vue'
import RealMembers from '@shared/components/RealMembers.vue'
import Landing3Steps from '@shared/components/Landing3Steps.vue'
import FaqModule from '@shared/components/FaqModule.vue'
import Contacts from '@shared/components/Contacts.vue'
import LandingPageFooter from '@shared/components/LandingPageFooter.vue'
import LandingStickyHeader from '@shared/components/LandingStickyHeader.vue'

const { t } = useI18n()
const route = useRoute()
const store = useStore()

const getCTAButtonClientRect = ref(null)

const corporate = computed(() => store.getters.corporate || null)
const lang = computed(() => store.getters.lang)
const isGerman = computed(
  () => lang.value.indexOf('de-') === 0 || lang.value === 'de',
)

const goTo = (path) => (window.location.href = path)

const ctaBtnTitle = computed(() => {
  const { query } = route
  if (query?.cta === 'check_elig') {
    return t('uhc_vpt_primer__hero__cta_check_eligibility')
  }

  return t('uhc_vpt_primer__hero__cta_start_today')
})

const handleCTAButtonClick = () => {
  const { query } = route
  if (query?.cta === 'check_elig') {
    goTo('https://form.jotform.com/KaiaHealth/vpt_concierge')
    return
  }

  goTo(
    'https://care.kaiahealth.com/appointments/embed_appt?dietitian_id=1491074&state_licenses=[AL,AR,AZ,CA,CO,CT,DC,DE,FL,GA,HI,IA,ID,IL,IN,KS,KY,LA,MA,MD,ME,MI,MN,MO,MS,MT,NC,ND,NE,NH,NJ,NM,NV,NY,OH,OK,OR,PA,RI,SC,SD,TN,TX,UT,VA,WA,WI,WV]&appt_type_ids=[336459]&org_level=true&vorp_ezimodnar=true',
  )
}

const showStickyHeader = () => {
  const rect = getCTAButtonClientRect?.value?.()
  return rect != null && rect.top <= -rect.height / 2
}

const receiveCTAButtonClientRect = (callback) => {
  getCTAButtonClientRect.value = callback
}
</script>

<style lang="scss">
.header-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 $kds-space-2_5x;
  gap: $kds-space-1x;
  height: 46px;

  &--kaia-logo {
    background-image: url('@shared/assets/img/kaia_text_logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 14px;
    width: 100px;

    @include media-up(md) {
      height: $kds-space-3x;
      width: 145px;
    }
  }

  @include media-up(md) {
    max-width: 1088px;
    margin: 0 auto;
    height: 70px;
  }
}
</style>
