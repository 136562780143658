<template>
  <div class="real-members">
    <div class="real-members__container">
      <div class="real-members__container__header">
        <h2 class="real-members__container__header--title">
          {{ t('b2b__landing_page__real_members__title') }}
        </h2>
      </div>
      <div class="real-members__container__cards">
        <div
          v-for="({ title, author }, index) in cards"
          :key="index"
          class="real-members__container__cards--card"
        >
          <p class="kds-typography-paragraph-large">{{ title }}</p>
          <p
            class="kds-typography-title-small"
            v-html="author"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'petite-vue-i18n'

const { t } = useI18n()

const cards = computed(() => [
  {
    title: t('b2b__landing_page__real_members__card_one__title'),
    author: t('b2b__landing_page__real_members__card_one__author'),
  },
  {
    title: t('b2b__landing_page__real_members__card_two__title'),
    author: t('b2b__landing_page__real_members__card_two__author'),
  },
  {
    title: t('b2b__landing_page__real_members__card_three__title'),
    author: t('b2b__landing_page__real_members__card_three__author'),
  },
])
</script>

<style lang="scss" scoped>
.real-members {
  padding: 40px 10px;

  @include media-up(lg) {
    padding: 96px 10px;
  }

  &__container {
    @extend %container;
    margin: auto;

    &__header {
      @include flex-container(column, center, md, center);
      @include margin(xxl, bottom);

      &--title {
        @include kaia-typography-h5($font-weight-bold);
        text-align: center;

        @include media-up(md) {
          @include kaia-typography-h4($font-weight-bold);
        }
      }
    }

    &__cards {
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr;

      @include media-up(md) {
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      }

      &--card {
        position: relative;
        padding: 40px;
        border-radius: 16px;
        @include flex-container(column, flex-start, lg);

        &:before {
          position: absolute;
          content: '\201C';
          font-size: 7rem;
          top: -40px;
        }

        &:nth-child(1) {
          background: $green-subtle;

          &:before {
            color: $green;
          }
        }

        &:nth-child(2) {
          background: $yellow-subtle;

          &:before {
            color: $yellow;
          }
        }

        &:nth-child(3) {
          background: $purple-subtle;

          &:before {
            color: $purple;
          }
        }
      }
    }
  }
}
</style>
