export default {
  ENTRY: 'entry',
  B2B: 'b2b',
  B2B_PARTNERS: 'b2b-partners',
  UHC_VPT_EMPLOYER_SEARCH: 'b2b-uhc-vpt-employer-search',
  UHC_VPT_INSURANCE_SELECTION: 'b2b-uhc-vpt-insurance-selection',
  UHC_VPT_PRIMER: 'b2b-uhc-vpt-primer',
  B2B_VOUCHER: 'b2b-voucher',
  B2B_SIGN_UP: 'b2b-sign-up',
  B2B_DOWNLOAD_LINK: 'b2b-download-link',
  B2B_SELECT_VERIFICATION_METHOD: 'b2b-select-verification-method',
  B2B_LOGIN: 'b2b-login',
  B2B_CORPORATE_ELIGIBILITY_LIST_VERIFICATION:
    'b2b-corporate-eligibility-list-verification',
  B2B_SOLERA_VERIFICATION: 'b2b-solera-verification',
  B2B_UHCHUB_VERIFICATION: 'b2b-uhchub-verification',

  B2B_SIMPLE_CHECK_IN: 'b2b-simple-check-in',
  B2B_SIMPLE_PLAN_OUTLINE: 'b2b-simple-plan-outline',
  B2B_SIMPLE_TESTIMONIAL: 'b2b-simple-testimonial',

  B2B_INFORMATION: 'b2b-information',
  B2B_INFORMATION_GENERIC: 'b2b-information-generic',

  SAML_SSO_INITIATED: 'saml-sso-initiated',
  SAML_SSO_COMPLETED: 'saml-sso-completed',
  SAML_SSO_ERROR: 'saml-sso-error',

  UHCHUB_INIT: 'uhchub-init',
  PERSONIFY_HEALTH_INIT: 'personify-health-init',
}
