<template>
  <transition name="sticky">
    <div
      v-if="shouldDisplay"
      class="sticky-header"
    >
      <div class="sticky-header-inner">
        <div class="logo">
          <img
            src="@shared/assets/img/logo-mobile.svg"
            alt="logo"
          />
        </div>

        <div class="caption">
          {{ $t('kaia_for_free') }}
        </div>

        <button
          type="button"
          class="btn btn-secondary get-started"
          @click="ctaWasClicked"
        >
          {{ stickyHeaderCaption }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { debounce } from '@shared/utils'

export default {
  name: 'StickyHeader',
  props: {
    showStickyHeader: {
      type: Function,
      default: () => false,
    },
    stickyHeaderCaption: {
      type: String,
      default: '',
    },
    ctaWasClicked: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      shouldDisplay: false,
    }
  },
  mounted() {
    this.debounceHandleScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.debounceHandleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.debounceHandleScroll)
    this.debounceHandleScroll = null
  },
  methods: {
    handleScroll() {
      this.shouldDisplay = this.showStickyHeader() && this.stickyHeaderCaption
    },
  },
}
</script>

<style lang="scss">
.sticky-header {
  position: fixed;
  z-index: 2;
  width: 100%;
  left: 0;
  top: 0;
  height: 90px;
  background: white;
  box-sizing: border-box;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $breakpoint-mobile) {
    height: 80px;
  }

  &-inner {
    width: 100%;
    max-width: 1200px;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $breakpoint-mobile) {
      padding: 0 24px;
    }

    .logo {
      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
    }

    .caption {
      font-weight: $font-weight-bold;
      font-size: 21px;
      margin: 0 30px 0 auto;

      @media (max-width: $breakpoint-mobile) {
        font-size: 17px;
        margin: 0;
      }
    }

    .get-started {
      @media (max-width: $breakpoint-mobile) {
        width: auto;
      }
    }
  }
}

.sticky-enter,
.sticky-leave-to {
  opacity: 0;
}

.sticky-enter-active,
.sticky-leave-active {
  transition: opacity 0.3s;
}
</style>
