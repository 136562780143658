<template>
  <ResponsiveLayout>
    <div class="kds-main-wrapper">
      <div class="kds-content-block">
        <p class="kds-typography-display-small">
          {{ $t('uhc_vpt_employer_search__title') }}
        </p>
        <p class="kds-typography-paragraph-large kds-color-text-secondary">
          {{ $t('uhc_vpt_employer_search__description') }}
        </p>
      </div>
      <div class="kds-content-block">
        <p class="kds-typography-title-xlarge">
          {{ $t('uhc_vpt_insurance_selection__options__title') }}
        </p>
        <div class="insurance-options__container">
          <div
            v-for="insurance in insurances"
            :key="insurance.title_key"
            class="insurance-options__item"
            tabindex="0"
            @click="insurance.action(insurance.title_key)"
            @keyup.enter="insurance.action(insurance.title_key)"
          >
            <img
              v-if="insurance.imgSrc"
              :src="insurance.imgSrc"
              :alt="$t(insurance.title_key)"
              class="insurance-options__item-logo"
            />
            <p
              v-else
              class="kds-typography-title-large"
            >
              {{ $t(insurance.title_key) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </ResponsiveLayout>
</template>

<script setup>
import { useRouter } from 'vue-router'

import ROUTE from '@us/app/router/appModule/names'

import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'

import unitedLogo from '@us/app/assets/img/unitedhealthcare-narrow.svg'
import surestLogo from '@us/app/assets/img/surest.svg'

const router = useRouter()

const goToVptPrimer = () => {
  router.push({
    name: ROUTE.UHC_VPT_PRIMER,
  })
}

const goToJotform = () => {
  window.location.href = 'https://form.jotform.com/KaiaHealth/patientslikeme'
}

const insurances = [
  {
    title_key: 'uhc_vpt_insurance_selection__uhc',
    action: goToVptPrimer,
    imgSrc: unitedLogo,
  },
  {
    title_key: 'uhc_vpt_insurance_selection__surest',
    action: goToVptPrimer,
    imgSrc: surestLogo,
  },
  {
    title_key: 'uhc_vpt_insurance_selection__cigna',
    action: goToJotform,
  },
  {
    title_key: 'uhc_vpt_insurance_selection__aetna',
    action: goToJotform,
  },
  {
    title_key: 'uhc_vpt_insurance_selection__anthem',
    action: goToJotform,
  },
  {
    title_key: 'uhc_vpt_insurance_selection__other',
    action: goToJotform,
  },
]
</script>

<style lang="scss" scoped>
.insurance-options {
  &__container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    gap: $kds-space-2x;
    justify-items: stretch;

    @include media-up(md) {
      grid-template-columns: 1fr 1fr 1fr;
      gap: $kds-space-2_5x;
    }
  }

  &__item {
    display: flex;
    height: 120px;
    justify-content: center;
    align-items: center;
    border-radius: $kds-shape-rounded-large;
    box-shadow: inset 0 0 0 2px $kds-color-line-on-interactive;
    background: $kds-color-background-subtle;
    cursor: pointer;

    &:hover {
      background: $kds-color-background-interactive-subtle-hover;
    }

    &:focus {
      box-shadow: inset 0 0 0 3px $kds-color-line-focus;
      background: $kds-color-background-interactive-subtle-hover;
    }

    &:active {
      background: $kds-color-background-interactive-subtle-active;
    }
  }

  &__item-logo {
    max-width: 50%;
    max-height: 50%;
  }
}
</style>
