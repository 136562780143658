<template>
  <div class="welcome-to-kaia self-test">
    <div class="self-test__layout">
      <div>
        <h3
          class="self-test__header"
          v-html="$t('b2b__red_flags_eu__sub_title')"
        />
        <div
          class="self-test__description"
          v-html="$t('b2b__red_flags_eu__notes')"
        />
      </div>
      <ConfirmationBox
        :label-title="$t('b2b__red_flags_eu__checkbox_label__medically_clear')"
        :cta-title="$t('b2b_sign_up_submit_button_text')"
        :checked="redFlagApproved"
        :cta-enabled="redFlagApproved"
        @change="handleConfirmationBoxChange"
        @submit="next"
      />
    </div>
  </div>
</template>

<script>
import ConfirmationBox from '@shared/components/ConfirmationBox.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'QuestionnaireSelfTestPrecautionsCOPD',
  components: {
    ConfirmationBox,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      redFlagApproved: 'assessment/redFlagApproved',
    }),
  },
  methods: {
    handleConfirmationBoxChange({ checked }) {
      this.$store.commit('assessment/updateRedFlagApproved', checked)
    },
    next() {
      this.handleNextEvent()
    },
  },
}
</script>

<style lang="scss">
@use '@shared/modules/assessment/views/css/SelfTest.scss' as *;
</style>
