<template>
  <div :class="getCustomRadioCSSClasses">
    <input
      :id="name"
      :checked="checked"
      :value="value"
      :data-qa="dataQA"
      :data-qa-value="dataQAValue"
      type="radio"
      class="custom-radio__input"
      @click="handleClickEvent"
      @change="handleChangeEvent"
      @focus="handleFocusEvent"
      @blur="handleBlurEvent"
    />
  </div>
</template>

<script>
export default {
  name: 'CustomRadio',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    dataQA: {
      type: String,
      default: '',
    },
    dataQAValue: {
      type: String,
      default: '',
    },
  },
  emits: ['change', 'focus', 'blur'],
  data() {
    return {
      focused: false,
    }
  },
  computed: {
    getCustomRadioCSSClasses() {
      const classes = ['custom-radio']

      if (this.checked) {
        classes.push('custom-radio--checked')
      } else if (this.focused) {
        classes.push('custom-radio--focused')
      } else {
        classes.push('custom-radio--default')
      }

      return classes
    },
  },
  methods: {
    handleChangeEvent(event) {
      const { checked, value } = event.target
      this.$emit('change', {
        checked,
        value,
      })
    },
    handleFocusEvent() {
      this.focused = true
      this.$emit('focus')
    },
    handleBlurEvent() {
      this.focused = false
      this.$emit('blur')
    },
    handleClickEvent() {
      this.$el.querySelector('.custom-radio__input').checked = true
    },
  },
}
</script>

<style lang="scss">
.quiz-radio {
  display: flex;
  align-items: center;
  background: $gray-base;
  border-radius: 8px;
  cursor: pointer;

  padding: 12px 16px;
  margin: 0 auto;

  &--checked {
    background: #b5d780;
  }

  &__label {
    width: 100%;
    color: $midnight;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;
    padding-right: 16px;
  }
}

.quiz-radio__smile-layout,
.quiz-radio__join-layout,
.quiz-radio__sad-layout {
  margin: 0 16px;
  margin-left: 12px;
  width: 36px;
  height: 36px;

  .custom-radio {
    background: transparent;
    border: none;
    width: 100%;
    height: 100%;
  }
}

.quiz-radio__smile-layout {
  background: url(@shared/assets/img/b2b/smile.svg) center no-repeat;
  background-size: 100%;
}

.quiz-radio__join-layout {
  background: url(@shared/assets/img/b2b/join.svg) center no-repeat;
  background-size: 100%;
}

.quiz-radio__sad-layout {
  background: url(@shared/assets/img/b2b/sad.svg) center no-repeat;
  background-size: 100%;
}

.quiz-radio__smiles-layout {
  flex-direction: row-reverse;
  padding-left: 5px;
}

.custom-radio {
  position: relative;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #fff;

  &__input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  &--checked {
    background-color: #000;
    border: 5px solid #fff;
  }

  &--focused {
    box-shadow: 0 0 0 2px $ocean;
    border: 5px solid #fff;
  }
}
</style>
