<template>
  <!-- eslint-disable vue/no-v-html -->
  <ResponsiveLayout>
    <div class="kds-main-wrapper">
      <CorporateHeader />
      <form
        id="profile_form"
        class="kds-content-block"
      >
        <p
          class="kds-typography-display-small"
          v-html="$t('b2b_email_code_welcome_text', { name: corporateName })"
        />
        <div
          class="kds-typography-paragraph-large"
          v-html="$t('b2b__saml_sso_signup_completed__description')"
        />
        <BaseBanner
          v-if="message"
          variant="warning"
        >
          <p v-html="$t(message)" />
        </BaseBanner>
        <TextField
          v-bind="fieldAttrs.firstName"
          :label="$t('b2b_sign_up_name_label')"
          autocomplete="given-name"
          data-qa="first-name"
        />

        <TextField
          v-bind="fieldAttrs.email"
          type="email"
          autocomplete="email"
          :label="$t('generic_field_email')"
          data-qa="email"
        />

        <DateOfBirthField
          v-bind="fieldAttrs.dateOfBirth"
          :lang="lang"
        />

        <PhonenumberField
          v-bind="fieldAttrs.phoneNumber"
          :label="$t('b2b_sign_up_phone_number_label')"
          :country="country"
          data-qa="phone-number"
        />

        <BaseBanner variant="info">
          <CheckboxField
            v-bind="fieldAttrs.acceptTerms"
            data-qa="accept-terms-and-conditions"
          >
            <DocumentViewer :link="$t('b2b__new_account__accept_terms')" />
          </CheckboxField>
        </BaseBanner>
      </form>
    </div>
    <template #actions>
      <BaseButton
        v-bind="submitAttrs"
        data-qa="submit"
        form="profile_form"
      >
        {{ $t('generic_submit') }}
      </BaseButton>
    </template>
  </ResponsiveLayout>
  <!--eslint-enable-->
</template>

<script setup>
/** @typedef {import('vue').Ref} Ref */
import { ref, computed, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

import { capitalizeFirstLetter } from '@shared/utils'
import useForm from '@shared/composables/useForm'

import ROUTE from '@us/app/router/appModule/names'

import BaseBanner from '@shared/components/BaseBanner.vue'
import BaseButton from '@shared/components/BaseButton.vue'

import TextField from '@shared/components/form/TextField.vue'
import DateOfBirthField from '@shared/components/form/DateOfBirthField.vue'
import PhonenumberField from '@shared/components/form/PhonenumberField.vue'
import CheckboxField from '@shared/components/form/CheckboxField.vue'
import DocumentViewer from '@shared/components/DocumentViewer.vue'

import { sendUserFeedback } from '@shared/api-endpoints/index.js'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'
import CorporateHeader from '@shared/components/CorporateHeader.vue'

const store = useStore()
const router = useRouter()
const route = useRoute()

const formData = computed(() => store.getters.formData)
const country = computed(() => store.getters.country)
const lang = computed(() => store.getters.lang)
const corporateName = computed(
  () => capitalizeFirstLetter(store.getters.corporate.title) || '',
)

/** @type {Ref<string | null>} */
const message = ref(null)

/** @type {Ref<{ firstName: string, email: string, dateOfBirth: string, phoneNumber: string, acceptTerms: boolean }>} */
const form = ref({
  firstName: formData.value.firstName,
  email: formData.value.email || route.query.email,
  dateOfBirth: formData.value.dateOfBirth || '',
  phoneNumber: formData.value.phoneNumber,
  acceptTerms: formData.value.acceptTerms || false,
})

watchEffect(() => {
  store.commit('formData', { ...form.value })
})

const formConfig = computed(() => ({
  firstName: { validations: ['required'] },
  email: { validations: ['required', 'email'] },
  dateOfBirth: { validations: ['dob'] },
  phoneNumber: { validations: ['required'] },
  acceptTerms: { validations: ['accepted'] },
}))

const { fieldAttrs, submitAttrs } = useForm(form, formConfig, {
  onSubmit: async () => {
    message.value = null

    // Set Email
    if (form.value.email?.toLowerCase() !== route.query.email?.toLowerCase()) {
      await store.dispatch('setUserEmail', form.value.email)
    }

    // Set Profile
    await store.dispatch('setUserProfile', {
      firstName: form.value.firstName,
      phoneNumber: form.value.phoneNumber.split(' ').join(''),
      acceptedDataProtection: form.value.acceptTerms,
      acceptedTracking: form.value.acceptTerms,
    })

    // Send Feedback
    await sendUserFeedback({
      dateOfBirth: form.value.dateOfBirth,
    })
  },
  onSuccess: () => {
    router.push({
      name: ROUTE.B2B_DOWNLOAD_LINK,
    })
  },
  onError: (error) => {
    message.value = null

    switch (error?.response?.status) {
      case 400:
        message.value = 'b2c_common_error_malformed_data'
        break
      case 403:
        message.value = 'b2b__saml_sso_signup_completed__email_taken'
        break
      default:
        message.value = 'b2c_common_error_unknown'
    }
  },
})
</script>
