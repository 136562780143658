<template>
  <div class="self-test-reevaluations-layout">
    <QuizNavHeader
      :stage-progress="stageProgress"
      :stage-count="stageCount"
      :stage="stage"
      :title="title"
      :back-button="backButton"
      @btn-back-clicked="goToPreviousRoute"
    />
    <router-view :handle-next-event="goToNextOrCompleteQuestionnaire" />
  </div>
</template>

<script>
import B2bMedia from '@shared/components/B2bMedia.vue'
import selfTestReevaluationsNavigation from '@shared/modules/assessment/mixins/selfTestReevaluationsNavigation'
import ROUTE from '@shared/modules/assessment/router/names'
import { useI18n } from 'petite-vue-i18n'
import QuizNavHeader from '@shared/components/Quiz/QuizNavHeader.vue'

export default {
  name: 'SelfTestReevaluations',
  components: {
    QuizNavHeader,
  },
  mixins: [selfTestReevaluationsNavigation],
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      media: B2bMedia,
    }
  },
  computed: {
    title() {
      return this.t('b2b_self_test_generic_assessment')
    },
    backButton() {
      return (
        this.$route.name !==
        ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_AVERAGE
      )
    },
  },
}
</script>

<style lang="scss">
.self-test-reevaluations-layout {
  height: 100%;

  .quiz-nav-header-wrap {
    position: absolute;
    left: 0;
    right: 0;

    .quiz-nav-header__title {
      margin: 0 auto;
      text-align: center;
    }
  }

  &--no-progress-bar .quiz-nav-progress {
    display: none;
  }
}
</style>
