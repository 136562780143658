<template>
  <InputWrapper
    :class="{
      'phonenumber-input': true,
      'with-country-code': showCountryCodeInput,
    }"
    :errors="errors"
    :has-errors="hasErrors"
    :hint="hint"
    :shrink-label="shrinkLabel"
    :focused="isFocused"
  >
    <template #prepend>
      <SelectField
        v-if="showCountryCodeInput"
        id="countryCode"
        v-model="countryCode"
        name="countryCode"
        :options="options"
        autocomplete="country"
        :style="{
          '--wrapper--box-shadow': '0',
          padding: '11px 27px 11px 16px',
        }"
        @focus="() => (isFocused = true)"
        @blur="() => (isFocused = false)"
        @change="handleCountryCodeChange"
      />
    </template>
    <template #label>
      {{ label }}
    </template>
    <input
      :id="name"
      ref="nationalNumberInput"
      v-model="phoneNumber"
      :name="name"
      v-bind="$attrs"
      type="tel"
      class="national-number"
      data-qa="phone-number"
      autocomplete="tel-national"
      @blur="onBlur"
      @focus="onFocus"
      @input="onInput"
      @keydown="handleKeyDown($event)"
    />
  </InputWrapper>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { countryList } from '@shared/utils'
import {
  getExampleNumber,
  parsePhoneNumber,
  AsYouType,
  getCountryCallingCode,
} from 'libphonenumber-js'
import { useI18n } from 'petite-vue-i18n'
import examples from 'libphonenumber-js/mobile/examples'
import InputWrapper from './InputWrapper.vue'
import SelectField from './SelectField.vue'

const { t } = useI18n()
const emit = defineEmits(['blur', 'input', 'focus'])

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  country: {
    type: String,
    required: true,
  },
  showCountryCodeInput: {
    type: Boolean,
    default: true,
  },
  label: {
    type: String,
    default: '',
  },
  errors: {
    type: Array,
    default: () => [],
  },
  hasErrors: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: '',
  },
  hint: {
    type: String,
    default: '',
  },
})

const isFocused = ref(false)

const nationalNumberInput = ref(null)
const countryCode = ref(props.country)
const phoneNumber = defineModel({
  type: String,
  default: '',
  set(val) {
    try {
      const parser = parsePhoneNumber(val, countryCode.value)

      if (!parser.isPossible()) {
        throw new Error()
      }

      return parser.formatInternational({ nationalPrefix: true })
    } catch {
      return new AsYouType().input(val)
    }
  },
})

const countryCallingCode = computed(
  () => `+${getCountryCallingCode(countryCode.value)}`,
)

const shrinkLabel = computed(() => {
  return isFocused.value || !!phoneNumber.value?.length
})

const label = computed(() => {
  if (props.country && !!phoneNumber.value?.length) {
    return [
      t('b2b_sign_up_phone_example_prefix'),
      getExampleNumber(countryCode.value, examples).formatInternational(),
    ].join(' ')
  }

  return props.label
})

const options = computed(() => {
  return countryList.map((country) => {
    const fullName = country[0]
    const shortName = country[1].toUpperCase()
    const code = country[2]

    return {
      text:
        countryCode.value === shortName
          ? `${shortName}`
          : `${fullName} (+${code})`,
      attrs: {
        value: shortName,
      },
    }
  })
})

const handleCountryCodeChange = () => {
  phoneNumber.value = countryCallingCode.value
  nationalNumberInput.value.focus()
}

const onBlur = () => {
  isFocused.value = false

  if (phoneNumber.value === countryCallingCode.value) {
    phoneNumber.value = ''
  }

  emit('blur', { countryCode: props.country })
}

const onFocus = () => {
  isFocused.value = true

  if (phoneNumber.value === '') {
    phoneNumber.value = countryCallingCode.value
  }

  emit('focus', { countryCode: props.country })
}

const onInput = () => {
  emit('input', { countryCode: props.country })
}

const handleKeyDown = (event) => {
  const { key } = event

  if (key === 'Backspace' || key === 'Delete') {
    if (phoneNumber.value === countryCallingCode.value) {
      event.preventDefault()
    }
  }
}

onMounted(() => {
  try {
    const parser = parsePhoneNumber(phoneNumber.value, countryCode.value)

    if (parser.isPossible()) {
      phoneNumber.value = parser.formatInternational({ nationalPrefix: true })
    }
  } catch {
    // do nothing
  }
})
</script>

<style lang="scss">
.phonenumber-input:not(.with-country-code) > .wrapper__container {
  gap: 0px;
}

.phonenumber-input.with-country-code > .wrapper__container {
  --wrapper--label-padding: 13px 16px 11px 0px;

  @include grid-container(70px 1fr, auto, 0);

  &.shrink-label {
    --wrapper--label-padding: 4px 16px 11px 0px;
  }
}

.wrapper {
  &__container {
    @include grid-container(1fr, flex-start, 0);

    &--prepend {
      .wrapper {
        --wrapper--box-shadow: 0;
      }
    }
    &--field {
      &--input {
        display: flex;
        height: 100%;

        .national-number {
          @include kaia-typography-generic(17px, 22.1px, $font-weight-medium);
          margin: auto 0;
          appearance: none;
          background-color: transparent;
          border: 0;
          color: $text-secondary;
          display: block;
          outline: 0;
          padding: 20px 16px 4px 0;
          width: 100%;
          box-sizing: border-box;

          &:-webkit-autofill {
            animation-name: onAutoFillStart;
          }

          &:not(:-webkit-autofill) {
            animation-name: onAutoFillCancel;
          }

          &::placeholder {
            color: $text-tertiary;
            font-weight: 500;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
