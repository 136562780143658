<template>
  <div class="value-proposition">
    <h2
      class="value-proposition__header value-proposition__header--de"
      v-html="$t('b2b__landing_page__value_proposition__title')"
    />
    <div class="value-proposition-sections">
      <div
        v-for="(card, index) in cards"
        :key="index"
        class="value-proposition-section"
      >
        <img
          :src="card.image"
          class="value-proposition-section__banner"
          :alt="card.title"
        />
        <div class="value-proposition-info">
          <h3
            class="value-proposition-info__title"
            v-html="card.title"
          />
          <div
            class="value-proposition-info__description"
            v-html="card.description"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'petite-vue-i18n'
import lifeFitImage from '@shared/assets/img/life_fit_4x.png'
import provenMethodsImage from '@shared/assets/img/proven_methods_4x.png'
import costsCoveredImage from '@shared/assets/img/costs_covered_4x.png'
import reclaimingLiveImage from '@shared/assets/img/reclaiming-life-image-two.png'
import env from '@shared/env.js'

const { t } = useI18n()

const cards = [
  {
    title: t('b2b__landing_page__value_proposition__life_fit__title'),
    description: t(
      'b2b__landing_page__value_proposition__life_fit__description',
    ),
    image: env.isMsk ? lifeFitImage : costsCoveredImage,
  },
  {
    title: t('b2b__landing_page__value_proposition__proven_methods__title'),
    description: t(
      'b2b__landing_page__value_proposition__proven_methods__description',
    ),
    image: provenMethodsImage,
  },
  {
    title: t('b2b__landing_page__value_proposition__costs_covered__title'),
    description: t(
      'b2b__landing_page__value_proposition__costs_covered__description',
    ),
    image: env.isMsk ? costsCoveredImage : reclaimingLiveImage,
  },
]
</script>

<style lang="scss" scoped>
.value-proposition {
  background-color: $light-blue;

  &__header {
    margin: 0;
    padding: 45px 24px 28px;
    text-align: center;

    @include kaia-typography-h5($font-weight-bold);

    &--de {
      font-size: 28px;
    }
  }

  &-sections {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
  }

  &-section {
    &__banner {
      display: block;
      max-width: 100%;
    }
  }

  &-info {
    padding: 24px 0 54px;

    &__title {
      margin: 0;
      padding: 0;
      margin-bottom: 11px;

      @include kaia-typography-h6($font-weight-bold);
    }

    &__description {
      @include kaia-typography-p2($font-weight-normal);
    }
  }
}

.value-proposition {
  @media (min-width: $size-small) {
    padding-bottom: 12px;
  }

  @media (min-width: $size-medium) {
    padding-bottom: 36px;
  }

  @media (min-width: $size-large) {
    padding-bottom: 72px;
  }

  @media (min-width: $size-large-x) {
    padding-bottom: 96px;
  }

  &__header {
    @media (min-width: $size-small) {
      padding-top: 48px;
      padding-bottom: 36px;
    }

    @media (min-width: $size-medium) {
      @include kaia-typography-h4($font-weight-bold);

      padding: 72px 100px 36px;
    }

    @media (min-width: $size-large) {
      padding: 72px 200px 36px;
    }

    @media (min-width: $size-large-x) {
      @include kaia-typography-h3($font-weight-bold);

      padding: 96px 200px 36px;
    }

    @media (min-width: $size-large-xx) {
      padding: 96px 200px 48px;
    }

    &--de {
      @media (min-width: $size-small) {
        @include kaia-typography-h5($font-weight-bold);
      }

      @media (min-width: $size-medium) {
        @include kaia-typography-h4($font-weight-bold);
      }

      @media (min-width: $size-large-x) {
        @include kaia-typography-h3($font-weight-bold);
      }
    }
  }

  &-sections {
    @media (min-width: $size-small) {
      padding: 0 24px;
    }

    @media (min-width: $size-medium) {
      padding: 0 54px;
    }

    @media (min-width: $size-large) {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 82px;
    }

    @media (min-width: $size-large-xx) {
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  &-section {
    @media (min-width: $size-medium) {
      display: flex;
      padding-bottom: 36px;
    }

    @media (min-width: $size-large) {
      display: block;
      flex-basis: 30%;
      padding-bottom: 0;
    }

    @media (min-width: $size-large-x) {
      flex-basis: 31%;
    }

    &__banner {
      @media (min-width: $size-medium) {
        max-height: 204px;
        margin: 0 auto;
      }

      @media (min-width: $size-large-x) {
        max-height: 215px;
      }
    }
  }

  &-info {
    @media (min-width: $size-small) {
      padding-bottom: 36px;
    }

    @media (min-width: $size-medium) {
      padding: 0 0 0 30px;
      flex-basis: 50%;
    }

    @media (min-width: $size-large) {
      padding-top: 18px;
      padding-left: 0;
    }

    @media (min-width: $size-large-xx) {
      padding-top: 24px;
    }

    &__title {
      @media (min-width: $size-large) {
        margin-bottom: 13px;
      }
    }
  }
}
</style>
