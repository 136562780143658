<template>
  <div :class="getCSSClasses">
    <div
      v-if="showingQuestions"
      ref="body"
      class="b-info b__transition b__transition--enter-from"
    >
      <div>
        <h3 class="b-info__header">
          {{ $t(headerText) }}
        </h3>
        <ul class="b-features">
          <li class="b-features__item b-features__item--move">
            {{ $t('b2b__simple_landing_page__plan_outline__card_move') }}
          </li>
          <li class="b-features__item b-features__item--relax">
            {{ $t('b2b__simple_landing_page__plan_outline__card_relax') }}
          </li>
          <li class="b-features__item b-features__item--learn">
            {{ $t('b2b__simple_landing_page__plan_outline__card_learn') }}
          </li>
        </ul>
      </div>

      <BaseButton
        :text="$t('generic_action_continue')"
        @click="handleCTAClick"
      />
    </div>

    <div
      v-else
      ref="header"
      class="b-info b__transition b__transition--enter-from"
    >
      <h3 class="b-info__header b-info__header--loading">
        {{ $t(loaderHeaderText) }}
      </h3>
      <DotsLoader />
    </div>
  </div>
</template>

<script>
import BaseButton from '@shared/components/BaseButton.vue'
import DotsLoader from '@us/app/components/DotsLoader.vue'
import Tracker from '@shared/Tracker'
import trackDetailedViewScreen from '@us/app/helpers/trackDetailedViewScreen.js'

export default {
  name: 'SimplePlanOutline',
  components: {
    DotsLoader,
    BaseButton,
  },
  props: {
    handleCTAButtonClick: {
      type: Function,
      default: () => {},
    },
    corporate: {
      type: Object,
      default: () => {},
    },
    utms: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showingQuestions: false,
      timerId: null,
    }
  },
  computed: {
    getCSSClasses() {
      const classes = ['simple-plan-outline', 'simple-plan-outline--loading']
      if (this.showingQuestions) {
        classes.pop()
      }
      return classes
    },
    checkInStatus() {
      return this.$store.getters.checkInStatus
    },
    headerText() {
      switch (this.checkInStatus) {
        case 'not_often':
          return 'b2b__simple_landing_page__plan_outline__title_option_not_often'

        case 'once_in_a_while':
          return 'b2b__simple_landing_page__plan_outline__title_option_once_in_a_while'

        case 'a_few_times_a_week':
          return 'b2b__simple_landing_page__plan_outline__title_option_a_few_times_a_week'

        default:
          return 'b2b__simple_landing_page__plan_outline__title_option_daily_battle'
      }
    },
    loaderHeaderText() {
      switch (this.checkInStatus) {
        case 'not_often':
          return 'b2b__simple_landing_page__plan_outline__loader_option_not_often'

        case 'once_in_a_while':
          return 'b2b__simple_landing_page__plan_outline__loader_option_once_in_a_while'

        case 'a_few_times_a_week':
          return 'b2b__simple_landing_page__plan_outline__loader_option_a_few_times_a_week'

        default:
          return 'b2b__simple_landing_page__plan_outline__loader_option_daily_battle'
      }
    },
    painFrequencyValue() {
      switch (this.checkInStatus) {
        case 'not_often':
          return 'Not often'

        case 'once_in_a_while':
          return 'Once in a while'

        case 'a_few_times_a_week':
          return 'A few times a week'

        default:
          return "It's a daily battle"
      }
    },
  },
  mounted() {
    this.timerId = setTimeout(() => {
      this.$refs.header.classList.remove('b__transition--enter-from')

      trackDetailedViewScreen(
        Tracker,
        'onb_pain_frequency_response',
        this.corporate,
        this.utms,
      )
    }, 100)

    this.timerId = setTimeout(() => {
      this.$refs.header.classList.add('b__transition--leave-to')

      this.showingQuestions = true
      this.timerId = setTimeout(() => {
        this.$refs.body.classList.remove('b__transition--enter-from')
      }, 100)
    }, 2000)
  },
  unmounted() {
    clearTimeout(this.timerId)
    this.timerId = null
  },
  methods: {
    handleCTAClick() {
      Tracker.trackKaiaEvent(
        {
          event_name: 'cb.onbrdg.submit_signup_flow_initial_question',
          app_area: 'signup_flow',
          action: 'submit',
          object_type: 'submit_signup_flow_initial_question',
          source: 'client_browser',
          screen_name: 'onb_pain_frequency screen',
        },
        {
          custom_payload: {
            corporate_id: this.corporate.id,
            corporate_name: this.corporate.title,
            corporate_channel: this.corporate.channel,
            corporate_type: this.corporate?.corporateTypes?.toString() || '',
            pain_frequency: this.painFrequencyValue,
            utm_medium: this.utms.medium || null,
            utm_source: this.utms.source || null,
            utm_campaign: this.utms.campaign || null,
            utm_content: this.utms.content || null,
          },
        },
      )

      this.timerId = setTimeout(() => {
        this.$refs.body.classList.add('b__transition--leave-to')
        this.handleCTAButtonClick()
      }, 100)
    },
  },
}
</script>

<style lang="scss">
.simple-plan-outline {
  width: 100%;
  max-width: 375px;
  background-color: $blue-strong;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: left;
  box-sizing: border-box;

  &--loading {
    align-items: center;
  }

  .b {
    &-info {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 0 20px;

      &__header {
        margin: 0;
        margin-bottom: 40px;
        color: $text-primary;

        @include kaia-typography-h6($font-weight-bold);

        &--loading {
          @include kaia-typography-h5($font-weight-bold);
        }
      }

      &__cta {
        margin-top: 16px;
      }
    }

    &__transition {
      transition:
        transform 0.25s ease-out,
        opacity 0.5s ease-out;
      transform: translateY(0);
      opacity: 1;

      &--enter-from {
        opacity: 0;
        transform: translateY(50px);
      }

      &--leave-to {
        opacity: 0;
        transform: translateY(-50px);
      }
    }

    &-features {
      margin: 0;
      padding: 0;
      padding-left: 40px;
      list-style-type: none;

      &__item {
        position: relative;
        z-index: 1;
        color: $text-primary;
        padding: 28px 100px 28px 16px;
        border-radius: 8px;
        margin-bottom: 16px;
        min-height: 40px;

        @include kaia-typography-p2($font-weight-bold);

        &::before {
          content: '';
          position: absolute;
          left: -30px;
          top: 50%;
          bottom: -12px;
          width: 3px;

          background: url("data:image/svg+xml,<svg viewBox='0 0 3 20' xmlns='http://www.w3.org/2000/svg'><path d='M1.5 0 V20' stroke='%237796B8' stroke-width='3' stroke-dasharray='12 8' stroke-linecap='square'/></svg>")
            left top repeat-y;
        }

        &::after {
          content: '';
          position: absolute;
          left: -40px;
          top: 50%;
          width: 24px;
          height: 24px;
          margin-top: -12px;

          border-radius: 12px;
          background:
            url('@shared/assets/img/checkbox-normal-white.svg') center center
              no-repeat,
            $text-primary;
          background-size: 60%;
        }

        &--move {
          background:
            url('@us/app/assets/img/thumbnail-move.svg') right center no-repeat,
            #e6f4fa;
          background-size: auto 100px;

          &::before {
            background: url("data:image/svg+xml,<svg viewBox='0 0 3 20' xmlns='http://www.w3.org/2000/svg'><path d='M1.5 0 V20' stroke='%237796B8' stroke-width='3' stroke-dasharray='12 8' stroke-dashoffset='12' stroke-linecap='square'/></svg>")
              left top repeat-y;
          }
        }

        &--relax {
          background:
            url('@us/app/assets/img/thumbnail-relax.svg') right center no-repeat,
            #f6eeff;
          background-size: auto 100px;

          &::before {
            top: 0;
          }
        }

        &--learn {
          background:
            url('@us/app/assets/img/thumbnail-learn.svg') right center no-repeat,
            #fef0d0;
          background-size: auto 100px;

          &::before {
            top: 0;
            bottom: 50%;
          }
        }
      }
    }
  }
}
</style>
