<template>
  <div class="quiz-content__inner self-test">
    <div class="self-test__layout">
      <h3
        class="self-test__header"
        v-html="$t('b2b_self_test_fear_of_movement_question')"
      />
      <ScoreSlider
        :max-score="10"
        :score="getFearOfMovementValue"
        :label="getFearOfMovementLabel"
        :min-text="$t('b2b_self_test_fear_of_movement_min')"
        :max-text="$t('b2b_self_test_fear_of_movement_max')"
        data-q-a="score-slider"
        @change="handleFearOfMovementChange"
        @tap="handleFearOfMovementChange"
      />
      <div class="interactive-button__layout">
        <BaseButton
          :disabled="interactiveButtonState === 'DISABLED'"
          :text="$t('generic_action_continue')"
          data-qa="submit"
          @click="handleNextEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseButton from '@shared/components/BaseButton.vue'
import ScoreSlider from '@shared/components/ScoreSlider.vue'

export default {
  name: 'SelfTestFearOfMovement',
  components: {
    BaseButton,
    ScoreSlider,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      interactiveButtonState: 'DISABLED',
    }
  },
  computed: {
    ...mapGetters('assessment', ['initialFearOfMovement']),
    getFearOfMovementValue() {
      return this.initialFearOfMovement === -1 ? 5 : this.initialFearOfMovement
    },
    getFearOfMovementLabel() {
      return this.initialFearOfMovement === -1
        ? ''
        : `${this.initialFearOfMovement}`
    },
  },
  created() {
    this.interactiveButtonState =
      this.initialFearOfMovement >= 0 && this.initialFearOfMovement <= 10
        ? 'ACTIVE'
        : 'DISABLED'
  },
  methods: {
    handleFearOfMovementChange({ value }) {
      this.interactiveButtonState = 'ACTIVE'
      this.$store.commit('assessment/updateInitialFearOfMovement', value)
    },
  },
}
</script>

<style lang="scss">
@use '@shared/modules/assessment/views/css/SelfTest.scss' as *;
</style>
