import * as apiRequest from '@shared/api-endpoints'
import ROUTE from '@shared/modules/assessment/router/names'
import { coerceAtLeast } from '@shared/utils'
import { sessionStorage } from '@shared/helpers/storage'
import cat from '@shared/modules/assessment/state/cat/index.js'
import selfTest from '@shared/modules/assessment/state/selfTest/index.js'
import selfTestReevaluation from '@shared/modules/assessment/state/selfTestReevaluation/index.js'

// this also defines which questionnaires are known
const questionnaireRoutes = {
  cat: ROUTE.ASSESSMENT_CAT_WELCOME,
  self_test: ROUTE.ASSESSMENT_SELF_TEST_WELCOME,
  self_test_reevaluation: ROUTE.ASSESSMENT_SELF_TEST_REEVALUATIONS_PAIN_AVERAGE,
}

const submitCAT = (store, { answers, score, group }) =>
  apiRequest.submitCAT({ answers, score, group })

const submitSelfTest = (store, { answers }) =>
  apiRequest.submitSelfTest({ answers })

const submitSelfTestReevaluations = (store, { data }) =>
  apiRequest.submitSelfTestReevaluations(data)

const getQuestionnaireConfig = async ({ commit }) => {
  const getCheckupsResponse = await apiRequest.getCheckups()

  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 7)

  const weekAheadISODate = currentDate.toISOString() // "YYYY-MM-DDTHRS:MIN:SEC.MSZ"

  const checkup = getCheckupsResponse.data.checkups.filter(
    (m) => m.completed_at === null && weekAheadISODate >= m.due,
  )[0]

  if (checkup === undefined) {
    console.info('no checkup found for user', getCheckupsResponse.data)
    await commit('setCurrentQuestionnaire', {})
    return
  }

  let uncompletedQuestionnaires = checkup.questionnaires
    .filter(
      (q) =>
        q.completed_at === null &&
        // only known questionnaires
        Object.keys(questionnaireRoutes).includes(q.key),
    )
    .map((q) => q.key)

  if (uncompletedQuestionnaires.length === 0) {
    console.info('no questionnaires found for user', getCheckupsResponse.data)
    await commit('setCurrentQuestionnaire', {})
    return
  }

  const completedQuestionnaires = checkup.questionnaires
    .filter(
      (q) =>
        q.completed_at !== null &&
        // only known questionnaires
        Object.keys(questionnaireRoutes).includes(q.key),
    )
    .map((q) => q.key)

  if (
    completedQuestionnaires.length === 0 &&
    uncompletedQuestionnaires.length === 1 &&
    uncompletedQuestionnaires[0] === 'self_test'
  ) {
    uncompletedQuestionnaires = ['self_test']
  }

  await commit('setCurrentQuestionnaire', {
    currentQuestionnaire: completedQuestionnaires.length,
  })
  await commit('setQuestionnaires', {
    questionnaires: [...completedQuestionnaires, ...uncompletedQuestionnaires],
  })
}

const openCurrentQuestionnaire = ({ getters }, { router }) => {
  if (getters.currentQuestionnaireRoute !== undefined) {
    router.push({
      name: getters.currentQuestionnaireRoute,
    })

    if (
      getters.currentQuestionnaireRoute === ROUTE.ASSESSMENT_SELF_TEST_WELCOME
    ) {
      sessionStorage.setItem('assessment:previousQuestionnaire', 'self_test')
    } else {
      sessionStorage.removeItem('assessment:previousQuestionnaire')
    }
  } else {
    apiRequest.sendBrazeEvent({
      name: 'milestone_assessment_done',
    })

    router.push(getters.finishRoute)
  }
}

export default {
  namespaced: true,
  modules: {
    cat,
    selfTest,
    selfTestReevaluation,
  },
  state: {
    finishRoute: { name: ROUTE.ASSESSMENT_COMPLETION },
    // The reason I need this is because initially we
    // have to display 4 questionnaires in the progress bar;
    questionnaires: [],
    currentQuestionnaire: 0,
  },
  getters: {
    finishRoute(state) {
      return state.finishRoute
    },
    questionnaireCount(state) {
      return state.questionnaires.length
    },
    currentQuestionnaire(state) {
      return state.currentQuestionnaire
    },
    currentQuestionnaireRoute(state) {
      return questionnaireRoutes[
        state.questionnaires[state.currentQuestionnaire]
      ]
    },
    percentage(state) {
      return Math.round(
        (100 * state.currentQuestionnaire) /
          coerceAtLeast(state.questionnaires.length, 1),
      )
    },
    partInfo(state) {
      const partNumber = state.currentQuestionnaire + 1
      const totalParts = coerceAtLeast(state.questionnaires.length, 1)

      return {
        partNumber: partNumber <= totalParts ? partNumber : totalParts,
        totalParts: totalParts,
      }
    },
  },
  mutations: {
    updateFields(state, { partialAnswers, moduleName }) {
      Object.entries(partialAnswers).forEach(([key, value]) => {
        state[moduleName].answers[key] = value
      })
    },
    setQuestionnaires(state, { questionnaires }) {
      state.questionnaires = questionnaires.filter(
        (q) => questionnaireRoutes[q] != null,
      )
    },
    setCurrentQuestionnaire(state, { currentQuestionnaire }) {
      state.currentQuestionnaire = currentQuestionnaire
    },
    updateCATScore(state, { moduleName }) {
      const { answers } = state[moduleName]
      let score = 0
      Object.keys(answers).map((key) => {
        score +=
          answers[key] === false || answers[key] === null ? 0 : answers[key]
        return score
      })
      state[moduleName].score = score
    },
  },
  actions: {
    updateCATField({ commit }, { name, value }) {
      commit('updateFields', {
        moduleName: 'cat',
        partialAnswers: {
          [name]: value,
        },
      })
    },
    updateCATScore({ commit }) {
      commit('updateCATScore', {
        moduleName: 'cat',
      })
    },
    submitCAT,
    submitSelfTest,
    submitSelfTestReevaluations,
    getQuestionnaireConfig,
    openCurrentQuestionnaire,
    async completeQuestionnaire({ commit, dispatch }, { router }) {
      await dispatch('getQuestionnaireConfig', { commit })
      dispatch('openCurrentQuestionnaire', { router })
    },
  },
}
