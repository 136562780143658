<template>
  <div class="partner-search-media">
    <div class="header">
      <AppLogo>{{ $t('b2b__partner_search__media_header') }}</AppLogo>
    </div>

    <div class="image-wrap">
      <img
        alt="Partner Search Media"
        class="image-wrap__banner"
        src="@shared/assets/img/b2b/partner-search-media.jpg"
      />
      <img
        alt="Partner Search Media"
        width="100%"
        src="@shared/assets/img/b2b/partner-search-media_0.5x.png"
      />
    </div>
  </div>
</template>

<script>
import AppLogo from '@shared/components/AppLogo.vue'

export default {
  name: 'PartnerSearchMedia',
  components: {
    AppLogo,
  },
}
</script>

<style lang="scss">
.partner-search-media {
  padding: 60px 0;
  background-color: $sky;
  box-sizing: border-box;
  min-height: 100dvh;

  .header {
    display: flex;
    align-items: center;
    padding: 0 100px;

    .app-logo-inner {
      max-width: 250px;

      @include kaia-typography-h5($font-weight-bold);
    }

    @media (max-width: $breakpoint-tablet) {
      display: none;
    }
  }

  .image-wrap {
    display: block;
    margin: 80px 0 0 100px;
    position: relative;

    &__banner {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  @media (max-width: $breakpoint-tablet) {
    padding: 0;
    min-height: unset;
  }
}
</style>
