<template>
  <div
    :data-qa="dataQA"
    :class="getCSSClasses"
  >
    <div :class="getControlCSSClasses">
      <slot />
    </div>
    <div
      v-if="errorMessage"
      class="form-field__error js-form-field__error"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormField',
  props: {
    hasFocus: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    isFilled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    cssClasses: {
      type: Array,
      default: () => [],
    },
    controlCSSClasses: {
      type: Array,
      default: () => [],
    },
    dataQA: {
      type: String,
      default: '',
    },
  },
  computed: {
    getCSSClasses() {
      return [
        'form-field',
        this.hasFocus ? 'form-field__focused' : '',
        this.isInvalid ? 'form-field__invalid js-form-field__invalid' : '',
        ...this.cssClasses,
      ]
    },
    getControlCSSClasses() {
      return [
        'form-field__control',
        this.isActive ? 'form-field--is-active' : '',
        this.isFilled ? 'form-field--is-filled' : '',
        ...this.controlCSSClasses,
      ]
    },
  },
}
</script>

<style lang="scss">
$animation-duration: 0.3s;

@mixin label-active() {
  font-size: 0.75rem;
  transform: translateY(-12px);
  position: absolute;
  color: $input-placeholder-color;
}
.form-field {
  position: relative;
  display: block;
  margin-bottom: 16px;

  &--is-active {
    .form-field__control {
      &:after {
        transform: scaleX(150);
      }
    }
    .form-field__label {
      @include label-active();
    }
  }
  &--is-filled {
    .form-field__label {
      @include label-active();
    }
  }
  &__invalid {
    .form-field__label {
      color: $danger;
    }
    .form-field__control {
      box-shadow: 0 0 0 2px $danger;
      // border: 2px solid $danger;
    }
  }
  &__focused {
    .form-field__label {
      color: $ocean;
    }
    .form-field__control {
      box-shadow: 0 0 0 2px $ocean;
    }
  }
}
.form-field__label {
  display: block;
  position: absolute;
  font-size: 17px;
  font-family: $font-family-base;
  line-height: 24px;
  font-weight: $font-weight-normal;
  left: 0;
  top: 0;
  margin: 0;
  padding: 13px 16px 11px;
  user-select: none;
  transition: all $animation-duration;
  pointer-events: none;
  color: $input-color;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}
.form-field__control {
  background: $subtle;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  width: 100%;
  box-sizing: border-box;

  &:after {
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transition: all $animation-duration;
    width: 1%;
  }
}
.form-field__input {
  appearance: none;
  background-color: transparent !important;
  border: 0;
  color: $input-color;
  display: block;
  font-size: 17px;
  line-height: 24px;
  outline: 0;
  padding: 20px 16px 4px 16px;
  width: 100%;
  font-weight: $font-weight-normal;
  font-family: $font-family-base;
  box-sizing: border-box;
  &[type='password'] {
    padding: 20px 46px 4px 16px;
  }
  &:-webkit-autofill {
    animation-name: onAutoFillStart;
  }
  &:not(:-webkit-autofill) {
    animation-name: onAutoFillCancel;
  }
}
.form-field__error {
  position: relative;
  color: $danger;
  font-size: 14px;
  top: 2px;
}
.form-field__success {
  position: relative;
  color: $mint-highlight;
  font-size: 14px;
  top: 2px;
}
.form-field__visibility {
  position: absolute;
  right: 16px;
  top: 12px;
  width: 24px;
  height: 24px;
  z-index: 1;
  background-image: url('@shared/assets/img/pass-invisible.svg');
  background-position: center center;
  background-repeat: no-repeat;
  &-visible {
    background-image: url('@shared/assets/img/pass-visible.svg');
  }
}
.form-field__loading {
  position: absolute;
  right: 16px;
  top: 12px;
  width: 24px;
  height: 24px;
  z-index: 1;

  .spinner {
    &:before {
      width: 24px;
      height: 24px;
      background-color: unset;
      background-size: unset;
    }
  }
}
@keyframes onAutoFillStart {
  from {
  }
  to {
  }
}
@keyframes onAutoFillCancel {
  from {
  }
  to {
  }
}
</style>
