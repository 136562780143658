<template>
  <ResponsiveLayout>
    <div class="saml-sso-spinner">
      <Spinner>
        {{ $t('b2b__SSO__redirecting_to_personify_health') }}
      </Spinner>
    </div>
  </ResponsiveLayout>
</template>

<script setup>
import { useStore } from 'vuex'
import Spinner from '@shared/components/Spinner.vue'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'
import env from '@shared/env.js'

const store = useStore()

const redirectTo = (path, query = {}) => {
  const url = new URL(path, env.VITE_API_BASE_URL)
  Object.entries(query).forEach(([key, value]) =>
    url.searchParams.append(key, value.toString()),
  )
  window.location.href = url.toString()
}

const connectWithPersonifyHealth = () => {
  redirectTo('saml-sp/personify-health/init')
}

const connectWithPersonifyHealthMock = () => {
  redirectTo(`saml-sp/personify-health/mocked`, {
    FirstName: 'test user sso',
    LastName: 'Automation',
    Email: 'saml_test_user@example.com',
    EmployeeID: 1,
    MemberID: 1,
    SponsorID: 28856,
    // this is internally used as uid, so using the email here to simplify user identification
    NameID: 'sebastian.stein@kaiahealth.com',
    redirect_host: window.location.hostname,
    redirect_port: window.location.port,
    redirect_scheme: window.location.protocol.split(':')[0],
  })
}

if (store.getters.corporate?.key === 'mocked-idp') {
  connectWithPersonifyHealthMock()
} else {
  connectWithPersonifyHealth()
}
</script>

<style lang="scss">
.saml-sso-spinner {
  height: 100dvh;

  .spinner {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.saml-sso {
  &__corporate_list_item {
    cursor: pointer;
    box-shadow: inset 0 -1px 0 0 $kds-color-line-subtle;
    padding: $kds-space-2x;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-of-type {
      box-shadow: unset;
    }

    &:hover {
      background-color: $kds-color-background-subtle;
    }

    &:focus {
      box-shadow: inset 0 0 0 3px $kds-color-line-focus;
      outline: none;
    }
  }
}
</style>
