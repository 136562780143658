import axios from 'axios'

import useAuthStore from '@shared/store/useAuthStore.js'
import env from '@shared/env.js'
import { datadogLogs } from '@datadog/browser-logs'

/**
 * Api instance for unauthenticated endpoints (and session creation):
 * - does not populate auth tokens into the headers
 * - pulls auth tokens from response headers if present
 * @type {AxiosInstance}
 */
const PublicApi = axios.create({
  baseURL: env.VITE_API_BASE_URL,
})

/**
 * Api instance for authenticated endpoints:
 * - populates auth tokens into request headers
 * - pulls auth tokens from response headers if present
 * - rotates auth tokens if required
 * @type {AxiosInstance}
 */
const PrivateApi = axios.create({
  baseURL: env.VITE_API_BASE_URL,
})

/**
 * Api instance for internal requests that should not have any interceptors
 * @type {axios.AxiosInstance}
 */
const RetryApi = axios.create({
  baseURL: env.VITE_API_BASE_URL,
})

function getAuthHeadersFromStore() {
  const authStore = useAuthStore()
  return Object.fromEntries(
    [
      ['access-token', authStore.accessToken],
      ['token-type', authStore.tokenType],
      ['client', authStore.client],
      ['guid', authStore.guid],
    ].filter(([, value]) => value !== null),
  )
}

function saveAuthHeadersToStore(response) {
  const authStore = useAuthStore()
  const { headers } = response
  if (!('access-token' in headers)) return
  if (headers['access-token'] === authStore.accessToken) return

  authStore.accessToken = headers['access-token']
  authStore.tokenType = headers['token-type']
  authStore.client = headers.client
  authStore.guid = headers.guid
  authStore.refreshToken = headers['refresh-token'] || ''
}

function assembleClientHeaders() {
  const authStore = useAuthStore()
  const appId = `kaia-${env.VITE_DISEASE}-WebCheckout`
  const clientVersion = env.VITE_VERSION
  const userAgent = `${appId}-${clientVersion}`
  const datadogSessionId = datadogLogs.getInternalContext()?.session_id
  const defaultHostIdentifier = 'unset' // indicates that the WebCheckout app is not rendered in a webview

  return {
    'x-app-id': appId,
    'x-user-agent': userAgent,
    'x-client-version': clientVersion,
    'x-host-client-app-id': authStore.hostClientAppId || defaultHostIdentifier,
    'x-host-client-version':
      authStore.hostClientVersion || defaultHostIdentifier,
    'x-session-id': datadogSessionId,
  }
}

/**
 * If a refresh and retry is reasonable (the original request failed due to
 * authorization issues, and we have a refresh token) it does it, otherwise it
 * just passes the original error on.
 * The refresh and retry would try to call the auth refresh endpoint, update
 * the auth store with the new token, and then retry the original request.
 * @returns {Promise<AxiosResponse<any>>}
 */
async function refreshAndRetry(error) {
  const authStore = useAuthStore()
  const originalRequest = error.config
  if (error.response.status === 401 && authStore.refreshToken != null) {
    try {
      // try to refresh the session
      // (the public api would on success automatically update the authStore)
      await PublicApi.post('v2/auth/refresh', null, {
        headers: {
          'refresh-token': authStore.refreshToken,
          client: authStore.client,
          guid: authStore.guid,
        },
      })
    } catch {
      authStore.invalidateTokens()
      // return original error
      throw error
    }

    // Retry the original request with the new access token
    originalRequest.headers = {
      ...originalRequest.headers,
      ...getAuthHeadersFromStore(),
    }
    // use the retry api so that no other interceptors are called (eg
    // pageloader) and to prevent an infinite loop and overwrite requestId to be
    // the one from the initial request to ensure the pageloader is in sync
    try {
      const retryResponse = await RetryApi(originalRequest)
      retryResponse.config.metadata.requestId = error.config.metadata.requestId
      return retryResponse
    } catch (retryError) {
      retryError.config.metadata.requestId = error.config.metadata.requestId
      throw retryError
    }
  }
  throw error
}

function logError(error) {
  datadogLogs.logger.warn(
    error.message,
    {
      error: {
        request_url: error?.config?.url,
        request_method: error?.config?.method,
        response_error:
          error?.response?.data?.error ?? error?.response?.data?.errors?.[0],
        response_code_text: error?.code,
        response_code: error?.response?.status,
      },
    },
    error,
  )
}

function initPublicApi() {
  PublicApi.interceptors.request.use((config) => {
    config.headers = {
      ...config.headers,
      ...assembleClientHeaders(),
    }
    return config
  })

  PublicApi.interceptors.response.use(
    (response) => {
      saveAuthHeadersToStore(response)
      return response
    },
    (error) => {
      logError(error)
      throw error
    },
  )
}

function initPrivateApi() {
  PrivateApi.interceptors.request.use(async (config) => {
    config.headers = {
      ...config.headers,
      ...getAuthHeadersFromStore(),
      ...assembleClientHeaders(),
    }
    return config
  })

  PrivateApi.interceptors.response.use(
    (response) => {
      saveAuthHeadersToStore(response)
      return response
    },
    async (error) => {
      try {
        return await refreshAndRetry(error)
      } catch (errorAfterRefreshAttempt) {
        logError(errorAfterRefreshAttempt)
        throw errorAfterRefreshAttempt
      }
    },
  )
}

/**
 * Initialize the API layer
 * Needs to be called at the start of the Vue application (and after the auth store is initialized)
 */
export const initApi = () => {
  initPublicApi()
  initPrivateApi()
}

export { PublicApi, PrivateApi }
