<template>
  <slot
    name="trigger"
    v-bind="{ open }"
  />
  <Transition name="modal">
    <div
      v-if="modal"
      class="modal-mask"
      v-bind="attrs"
    >
      <div
        v-click-outside="handleClickoutside"
        class="modal-mask__container"
        :style="{ width: `${width}px` }"
      >
        <div class="modal-mask__container--header">
          <slot name="header">
            <p class="modal-mask__container--header--title">{{ title }}</p>
          </slot>
          <button
            data-qa="modal-close-button"
            class="modal-mask__container--header--close-button"
            type="button"
            @click="close"
          >
            <CloseIcon />
          </button>
        </div>

        <div class="modal-mask__container--body">
          <slot />
        </div>

        <div class="modal-mask__container--footer">
          <slot
            name="footer"
            v-bind="{ close }"
          />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { ref, useAttrs } from 'vue'
import CloseIcon from '@shared/assets/icon/CloseIcon.vue'

const modal = ref(false)
const readyForClickOutside = ref(false)
const emit = defineEmits(['open', 'close'])

const attrs = useAttrs()

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  width: {
    type: Number,
    default: 592,
  },
  canClickOutside: {
    type: Boolean,
    default: false,
  },
})

const open = () => {
  modal.value = true
  emit('open')
  setTimeout(() => {
    readyForClickOutside.value = true
  }, 0)
}

const close = () => {
  modal.value = false
  readyForClickOutside.value = false
  emit('close')
}

const handleClickoutside = (e) => {
  if (props.canClickOutside && readyForClickOutside.value) {
    if (modal.value) {
      readyForClickOutside.value = false
      e.stopPropagation()
      e.preventDefault()
      close()
    }
  }
}

defineExpose({ open, close })
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;

  &__container {
    position: relative;
    margin: auto;
    padding: 20px 24px;
    background-color: #fff;
    box-sizing: border-box;
    max-height: 100%;
    overflow-y: auto;
    border-radius: $border-radius;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;

    &--header {
      padding: 8px 40px 8px 0;
      @include flex-container(row, space-between, md, flex-start);

      &--title {
        margin: 0;
        @include kaia-typography-generic(21px, 27.3px, $font-weight-bold);
      }

      &--close-button {
        position: absolute;
        display: flex;
        padding: 12px;
        top: 22px;
        right: 14px;
        @extend %no-style-button;
      }
    }

    &--body {
      margin: 20px 0;
      padding: 0;
      max-height: 80vh;
      overflow: auto;
    }
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
