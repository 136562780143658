<template>
  <div class="quiz-content__inner activities self-test">
    <div class="self-test__layout">
      <div>
        <h3
          class="self-test__header"
          v-html="$t('b2b_self_test_activities_question')"
        />
        <div
          class="self-test__description"
          v-html="$t('b2b_self_test_activities_description')"
        />
      </div>
      <fieldset
        :disabled="formIsDisabled"
        class="form-container"
      >
        <div
          v-for="fitnessActivity in fitnessActivities"
          :key="fitnessActivity.value"
          :class="[
            'quiz-checkbox',
            { 'quiz-checkbox--checked': fitnessActivity.checked },
          ]"
          @click="
            handleFitnessActivityChange(
              fitnessActivity,
              !fitnessActivity.checked,
            )
          "
        >
          <label class="quiz-checkbox__label kaia-p2-semi-bold">
            {{ $t(fitnessActivity.label) }}
          </label>
          <div class="quiz-checkbox__layout">
            <custom-checkbox
              :value="fitnessActivity.value"
              :checked="fitnessActivity.checked"
              :data-q-a="'fitness-activity-' + fitnessActivity.value"
            />
          </div>
        </div>
      </fieldset>
      <BaseBanner
        v-if="message.show"
        :variant="message.type"
        :title="message.title"
      >
        <div v-html="$t(message.text)" />
      </BaseBanner>
      <div :class="interactiveButtonCSSClasses">
        <BaseButton
          :disabled="interactiveButtonState === 'DISABLED'"
          :text="interactiveButtonText"
          data-qa="submit"
          @click="next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BaseBanner from '@shared/components/BaseBanner.vue'
import CustomCheckbox from '@shared/components/CustomCheckbox.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import { useI18n } from 'petite-vue-i18n'
import env from '@shared/env.js'

const defaultMessage = {
  text: '',
  title: '',
  type: 'warning',
  show: false,
  code: null,
}

const getDefaultFitnessActivities = (IS_COPD) =>
  IS_COPD
    ? [
        {
          label: 'b2b_self_test_activities_option_dressing',
          value: 'dressing',
          checked: false,
        },
        {
          label: 'b2b_self_test_activities_option_walk_100m',
          value: 'walk-100m',
          checked: false,
        },
        {
          label: 'b2b_self_test_activities_option_walk_longer_than_100m',
          value: 'walk-longer-than-100m',
          checked: false,
        },
        {
          label: 'b2b_self_test_activities_option_walk_uphill',
          value: 'walk-uphill',
          checked: false,
        },
        {
          label: 'b2b_self_test_activities_option_strenous_exercise',
          value: 'strenous-exercise',
          checked: false,
        },
      ]
    : [
        {
          label: 'b2b_self_test_activities_option_competitive_sports',
          value: 'hard-sports',
          checked: false,
        },
        {
          label: 'b2b_self_test_activities_option_recreational_sports',
          value: 'easy-sports',
          checked: false,
        },
        {
          label: 'b2b_self_test_activities_option_climbing_stairs',
          value: 'everyday-walking',
          checked: false,
        },
        {
          label: 'b2b_self_test_activities_option_household_chores',
          value: 'household-tasks',
          checked: false,
        },
      ]

export default {
  name: 'QuestionnaireSelfTestActivities',
  components: {
    BaseButton,
    CustomCheckbox,
    BaseBanner,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      message: { ...defaultMessage },
      formIsDisabled: false,
      interactiveButtonState: 'ACTIVE',
    }
  },
  computed: {
    ...mapGetters({
      fitnessActivities: 'assessment/fitnessActivities',
      selectedFitnessActivities: 'assessment/selectedFitnessActivities',
    }),
    ...mapGetters('assessment', ['selfTestAnswers']),
    interactiveButtonText() {
      return this.fitnessActivities.some((a) => a.checked)
        ? this.t('generic_action_continue')
        : this.t('b2b_self_test_activities_none_of_these')
    },
    interactiveButtonCSSClasses() {
      return [
        'interactive-button__layout',
        this.fitnessActivities.some((a) => a.checked)
          ? ''
          : 'interactive-button-outlined',
      ]
    },
  },
  created() {
    if (!this.fitnessActivities.length) {
      this.$store.commit(
        'assessment/setFitnessActivities',
        getDefaultFitnessActivities(env.isCopd),
      )
    }
  },
  methods: {
    ...mapActions('assessment', ['submitSelfTest']),
    async next() {
      if (this.formIsDisabled) {
        return
      }
      this.formIsDisabled = true
      this.interactiveButtonState = 'PROCESSING'

      if (env.isCopd) {
        this.message.show = false
        try {
          await this.submitSelfTest({ answers: this.selfTestAnswers })
        } catch {
          this.message.show = true
          this.message.type = 'warning'
          this.message.title = this.t('generic__error_title')
          this.message.text = this.t('generic__error_message', {
            email: env.VITE_SUPPORT_EMAIL,
          })

          this.interactiveButtonState = 'ACTIVE'
          return
        }
      }

      this.handleNextEvent()
    },
    handleFitnessActivityChange(fitnessActivity, checked) {
      if (this.formIsDisabled) {
        return
      }

      this.$store.commit('assessment/updateFitnessActivities', {
        value: fitnessActivity.value,
        checked,
      })
    },
  },
}
</script>

<style lang="scss">
@use '@shared/modules/assessment/views/css/SelfTest.scss' as *;

.activities {
  .message-box {
    margin-bottom: 0;
  }

  .interactive-button__layout {
    margin: 32px auto 0;
  }
}
</style>
