<template>
  <div class="welcome-theme">
    <QuizNavHeader
      :stage="currentQuestionnaire"
      :stage-count="questionnaireCount"
      :stage-progress="10"
      :title="$t('b2b_quiz_cat_welcome_title')"
      :back-button="false"
      :info-text="$t('b2b_quiz_cat_copyright')"
    />

    <QuizContentContainer>
      <div class="kds-main-wrapper">
        <p
          class="kds-typography-display-small kds-typography-center"
          v-html="$t('medical_profile_cat_title')"
        />

        <p
          class="kds-typography-paragraph-large kds-typography-center"
          v-html="$t('b2b_quiz_cat_welcome_content')"
        />

        <div class="figure">
          <img
            src="@shared/assets/img/b2b/relaxation.svg"
            alt=""
          />
        </div>

        <BaseButton
          data-qa="submit"
          @click="startSurvey"
        >
          {{ $t('b2b_self_test_button_start') }}
        </BaseButton>
      </div>
    </QuizContentContainer>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import QuizNavHeader from '@shared/components/Quiz/QuizNavHeader.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import QuizContentContainer from '@shared/components/Quiz/QuizContentContainer.vue'

const router = useRouter()
const store = useStore()

const currentQuestionnaire = computed(
  () => store.getters['assessment/currentQuestionnaire'],
)

const questionnaireCount = computed(
  () => store.getters['assessment/questionnaireCount'],
)

const startSurvey = () => {
  router.push({
    path: 'questions',
  })
}
</script>

<style lang="scss" scoped>
.figure {
  text-align: center;
  display: none;

  img {
    width: 70%;
    max-width: 420px;
  }

  @media (max-width: $breakpoint-tablet) {
    display: block;
  }
}

.welcome-theme {
  .quiz-content-container {
    padding: 20px;
  }
}
</style>
