import ROUTE from '@us/app/router/appModule/names'
import env from '@shared/env.js'

export default [
  ...(env.isProd
    ? []
    : [
        {
          path: '/uhchub/init',
          name: ROUTE.UHCHUB_INIT,
          component: () => import('@us/app/views/UhchubInit.vue'),
        },
        {
          path: '/personifyhealth/init',
          name: ROUTE.PERSONIFY_HEALTH_INIT,
          component: () => import('@us/app/views/PersonifyHealthInit.vue'),
        },
      ]),
  {
    path: '/b2b/:pathMatch(.*)*',
    redirect: (to) => {
      return {
        path: to.path.replace(/^\/b2b/, ''),
        query: to.query,
      }
    },
  },
  {
    path: '/partners',
    name: ROUTE.B2B_PARTNERS,
    component: () => import('@us/app/views/B2BPartners.vue'),
    meta: {
      name: 'onb_employer_search',
    },
  },
  {
    path: '/uhc_vpt_employer_search',
    name: ROUTE.UHC_VPT_EMPLOYER_SEARCH,
    component: () => import('@us/app/views/UhcVptEmployerSearch.vue'),
    meta: {
      name: 'uhc_vpt_employer_search',
    },
  },
  {
    path: '/uhc_vpt_insurance_selection',
    name: ROUTE.UHC_VPT_INSURANCE_SELECTION,
    component: () => import('@us/app/views/UhcVptInsuranceSelection.vue'),
    meta: {
      name: 'uhc_vpt_insurance_selection',
    },
  },
  {
    path: '/uhc_vpt_primer',
    name: ROUTE.UHC_VPT_PRIMER,
    component: () => import('@us/app/views/UhcVptPrimer.vue'),
    meta: {
      name: 'uhc_vpt_primer',
    },
  },
  {
    path: '/information',
    name: ROUTE.B2B_INFORMATION_GENERIC,
    component: () =>
      env.isCopd
        ? import('@us/app/views/LandingPageCOPD.vue')
        : import('@us/app/views/LandingPageMSK.vue'),
    meta: {
      name: 'generic_landing_page',
    },
  },
  {
    // TODO can this be a redirect (pulling redirect logic from hooks)?
    path: '/:corporate_key?',
    name: ROUTE.ENTRY,
    component: () => import('@us/app/views/B2B.vue'),
  },
  {
    // TODO can this be a redirect (pulling redirect logic from hooks)?
    path: '/:corporate_key/select-verification-method',
    name: ROUTE.B2B_SELECT_VERIFICATION_METHOD,
    component: () => import('@us/app/views/B2B.vue'),
  },
  {
    path: '/:corporate_key/information',
    name: ROUTE.B2B_INFORMATION,
    component: () =>
      env.isCopd
        ? import('@us/app/views/LandingPageCOPD.vue')
        : import('@us/app/views/LandingPageMSK.vue'),
    meta: {
      name: 'corporate_landing_page',
    },
  },
  {
    path: '/:corporate_key/information/check-in',
    name: ROUTE.B2B_SIMPLE_CHECK_IN,
    component: () =>
      import('@us/app/views/LandingPageMSKReflectiveOnboarding.vue'),
  },
  {
    path: '/:corporate_key/information/plan-outline',
    name: ROUTE.B2B_SIMPLE_PLAN_OUTLINE,
    component: () =>
      import('@us/app/views/LandingPageMSKReflectiveOnboarding.vue'),
  },
  {
    path: '/:corporate_key/information/testimonial',
    name: ROUTE.B2B_SIMPLE_TESTIMONIAL,
    component: () =>
      import('@us/app/views/LandingPageMSKReflectiveOnboarding.vue'),
  },
  {
    path: '/:corporate_key/voucher/:voucherCode?',
    name: ROUTE.B2B_VOUCHER,
    component: () => import('@us/app/views/B2BVoucher.vue'),
    meta: {
      name: 'onb_voucher_verification',
    },
  },
  {
    path: '/:corporate_key/eligibility-list',
    name: ROUTE.B2B_CORPORATE_ELIGIBILITY_LIST_VERIFICATION,
    component: () =>
      import('@us/app/views/B2BCorporateEligibilityVerification.vue'),
    meta: {
      name: 'onb_eligibility_verification',
    },
  },
  {
    path: '/:corporate_key/solera-verification',
    name: ROUTE.B2B_SOLERA_VERIFICATION,
    component: () => import('@us/app/views/SoleraVerification.vue'),
    meta: {
      name: 'onb_eligibility_verification',
    },
  },
  {
    path: '/:corporate_key/uhchub-verification',
    name: ROUTE.B2B_UHCHUB_VERIFICATION,
    component: () => import('@us/app/views/UhchubVerification.vue'),
    meta: {
      name: 'onb_eligibility_verification',
    },
  },
  {
    path: '/:corporate_key/sign-up',
    name: ROUTE.B2B_SIGN_UP,
    component: () => import('@us/app/views/B2BSignUp.vue'),
    meta: {
      name: 'onb_account_creation',
    },
  },
  {
    path: '/:corporate_key/login',
    name: ROUTE.B2B_LOGIN,
    component: () => import('@us/app/views/B2BLogin.vue'),
    meta: {
      name: 'onb_signin',
    },
  },
  {
    path: '/:corporate_key/download-link',
    name: ROUTE.B2B_DOWNLOAD_LINK,
    component: () => import('@us/app/views/DownloadLink.vue'),
    meta: {
      name: 'onb_download_prompt',
    },
  },
  {
    path: '/:corporate_key/sso-initiated',
    name: ROUTE.SAML_SSO_INITIATED,
    component: () => import('@us/app/views/SamlSSOInitiated.vue'),
  },
  {
    path: '/:corporate_key/sso',
    redirect: { name: ROUTE.SAML_SSO_COMPLETED },
  },
  {
    path: '/:corporate_key/sso-completed',
    name: ROUTE.SAML_SSO_COMPLETED,
    component: () => import('@us/app/views/SamlSSOCompleted.vue'),
  },
  {
    path: '/:corporate_key/sso-error',
    name: ROUTE.SAML_SSO_ERROR,
    component: () => import('@us/app/views/SamlSSOError.vue'),
  },
]
