<template>
  <div class="quiz-content__inner pain-duration self-test">
    <div class="self-test__layout">
      <div>
        <h3
          class="self-test__header pain-duration__header"
          v-html="$t('b2b_self_test_pain_duration_question')"
        />
        <fieldset
          :disabled="formIsDisabled"
          class="form-container"
        >
          <div
            v-for="chronificationLevel in chronificationLevels"
            :key="chronificationLevel.value"
            :class="[
              'quiz-radio',
              { 'quiz-radio--checked': chronificationLevel.checked },
            ]"
            @click="handleChronificationLevelChange(chronificationLevel)"
          >
            <label class="quiz-radio__label kaia-p2-semi-bold">
              {{ $t(chronificationLevel.label) }}
            </label>
            <div class="quiz-radio__layout">
              <custom-radio
                :value="chronificationLevel.value"
                :checked="chronificationLevel.checked"
                :data-q-a="'pain-duration-' + chronificationLevel.value"
              />
            </div>
          </div>
        </fieldset>
      </div>
      <div class="interactive-button__layout">
        <BaseButton
          :disabled="interactiveButtonState === 'DISABLED'"
          :text="$t('generic_action_continue')"
          data-qa="submit"
          @click="handleNextEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomRadio from '@shared/components/CustomRadio.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import env from '@shared/env'
import { useI18n } from 'petite-vue-i18n'

const getDefaultChronificationLevels = () =>
  env.isMskEu
    ? [
        {
          value: 'less-than-6-weeks',
          label: 'b2b_self_test_pain_duration_option_below_6_weeks',
          checked: false,
        },
        {
          value: '6-to-12-weeks',
          label: 'b2b_self_test_pain_duration_option_6_to_12_weeks',
          checked: false,
        },
        {
          value: '12-to-24-weeks',
          label: 'b2b_self_test_pain_duration_option_over_12_weeks',
          checked: false,
        },
      ]
    : [
        {
          value: 'less-than-4-weeks',
          label: 'b2b_self_test_pain_duration_option_below_4_weeks',
          checked: false,
        },
        {
          value: '4-to-12-weeks',
          label: 'b2b_self_test_pain_duration_option_4_to_12_weeks',
          checked: false,
        },
        {
          value: '12-to-24-weeks',
          label: 'b2b_self_test_pain_duration_option_3_to_6_months',
          checked: false,
        },
        {
          value: 'more-than-24-weeks',
          label: 'b2b_self_test_pain_duration_option_over_24_weeks',
          checked: false,
        },
      ]

export default {
  name: 'QuestionnaireSelfTestPainDuration',
  components: {
    BaseButton,
    CustomRadio,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      timeout: null,
      formIsDisabled: false,
      interactiveButtonState: 'DISABLED',
    }
  },
  computed: {
    ...mapGetters('assessment', [
      'chronificationLevels',
      'selectedChronificationLevels',
    ]),
    isValid() {
      return this.selectedChronificationLevels.length
    },
  },
  created() {
    if (!this.chronificationLevels.length) {
      this.$store.commit(
        'assessment/setChronificationLevels',
        getDefaultChronificationLevels(),
      )
    }
    this.interactiveButtonState = this.chronificationLevels.some(
      (l) => l.checked,
    )
      ? 'ACTIVE'
      : 'DISABLED'
  },
  methods: {
    handleChronificationLevelChange(chronificationLevel) {
      this.$store.commit(
        'assessment/updateChronificationLevels',
        chronificationLevel,
      )

      this.interactiveButtonState = 'ACTIVE'
    },
  },
}
</script>

<style lang="scss">
@use '@shared/modules/assessment/views/css/SelfTest.scss' as *;

.pain-duration {
  &__header {
    margin-bottom: 32px;
  }

  .interactive-button__layout {
    margin: 32px auto 0;
  }
}
</style>
