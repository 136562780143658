<template>
  <div class="b2b-media-signup">
    <div>
      <div class="social-proof-illustration">
        <img
          src="@shared/assets/img/kaia_bike.svg"
          alt="social-proof"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'B2BSignUpMedia',
}
</script>

<style lang="scss" scoped>
.b2b-media-signup {
  background-color: $blue-accent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100dvh;
  padding: 160px 0 60px;

  .social-proof-illustration {
    img {
      display: block;
      width: 100%;
      margin: 60px auto 60px;
    }
  }
}
</style>
