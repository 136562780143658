<template>
  <div class="quiz-content__inner self-test">
    <div class="self-test__layout">
      <div class="form-container">
        <h3
          class="self-test__header"
          v-html="$t('b2b_self_test_productivity_question')"
        />
        <div
          class="self-test__description"
          v-html="$t('b2b_self_test_productivity_description')"
        />
      </div>
      <ScoreSlider
        :max-score="10"
        :score="getScoreSliderValue"
        :label="getScoreSliderLabel"
        :min-text="$t('b2b_self_test_productivity_slider_min_hint')"
        :max-text="$t('b2b_self_test_productivity_slider_max_hint')"
        data-q-a="score-slider"
        @change="handleSliderChange"
        @tap="handleSliderChange"
      />
      <div class="interactive-button__layout">
        <BaseButton
          :disabled="interactiveButtonState === 'DISABLED'"
          :text="$t('generic_action_continue')"
          data-q-a="submit"
          @click="handleNextEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseButton from '@shared/components/BaseButton.vue'
import ScoreSlider from '@shared/components/ScoreSlider.vue'

export default {
  name: 'SelfTestProductivity',
  components: {
    ScoreSlider,
    BaseButton,
  },
  props: {
    handleNextEvent: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      interactiveButtonState: 'DISABLED',
    }
  },
  computed: {
    ...mapGetters('assessment', ['productivity']),
    isValid() {
      return this.productivity >= 0 && this.productivity <= 10
    },
    getScoreSliderValue() {
      return this.productivity === -1 ? 5 : this.productivity
    },
    getScoreSliderLabel() {
      return this.productivity === -1 ? '' : `${this.productivity}`
    },
  },
  created() {
    this.interactiveButtonState = this.isValid ? 'ACTIVE' : 'DISABLED'
  },
  methods: {
    handleSliderChange({ value }) {
      this.interactiveButtonState = 'ACTIVE'
      this.$store.commit('assessment/updateProductivity', value)
    },
  },
}
</script>

<style lang="scss">
@use '@shared/modules/assessment/views/css/SelfTest.scss' as *;
</style>
