<template>
  <div class="contacts">
    <h2 class="contacts__header">
      {{ $t('b2b__landing_page__contacts__title') }}
    </h2>
    <div class="contacts__text">
      {{ $t('b2b__landing_page__contacts__description') }}
    </div>
    <BaseButton
      variant="outlined"
      class="contacts__cta"
      :text="$t('b2b__landing_page__generic__contact_btn__title')"
      @click="goToMailTo()"
    />
  </div>
</template>

<script setup>
import BaseButton from '@shared/components/BaseButton.vue'
import env from '@shared/env.js'

const goToMailTo = () => {
  window.location.href = `mailto:${env.VITE_SUPPORT_EMAIL}`
}
</script>

<style lang="scss">
.contacts {
  background-color: $white;
  padding: 30px 24px 0;
  text-align: center;

  &__header {
    margin: 0;
    padding-bottom: 9px;

    @include kaia-typography-h5($font-weight-bold);
  }

  &__text {
    @include kaia-typography-p2($font-weight-normal);
  }

  &__cta {
    width: fit-content;
    margin: 31px auto 0;
  }
}

.contacts {
  @media (min-width: $size-small) {
    padding: 48px 24px 0;
  }

  @media (min-width: $size-medium) {
    padding: 72px 82px 0;
  }

  @media (min-width: $size-large-x) {
    padding: 96px 82px 0;
  }

  @media (min-width: $size-large-xx) {
    margin: 0 auto;
    width: 1200px;
  }

  &__header {
    @media (min-width: $size-small) {
      padding-bottom: 11px;
    }

    @media (min-width: $size-medium) {
      padding-bottom: 17px;

      @include kaia-typography-h4($font-weight-bold);
    }

    @media (min-width: $size-large-x) {
      @include kaia-typography-h3($font-weight-bold);
    }
  }

  &__text {
    @media (min-width: $size-medium) {
      @include kaia-typography-h6($font-weight-normal);
    }
  }

  &__cta {
    @media (min-width: $size-medium) {
      margin-top: 32px;
    }
  }
}
</style>
