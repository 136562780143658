<template>
  <div :class="cssClasses">
    <QuizNavHeader
      :stage-progress="stageProgress"
      :stage-count="stageCount"
      :stage="stage"
      :title="$t(title)"
      :info-text="infoText"
      :back-button="backButton"
      @btn-back-clicked="goToPreviousRoute"
    />
    <router-view :handle-next-event="goToNextOrCompleteQuestionnaire" />
  </div>
</template>

<script>
import B2bMedia from '@shared/components/B2bMedia.vue'
import selfTestNavigation from '@shared/modules/assessment/mixins/selfTestNavigation'
import ROUTE from '@shared/modules/assessment/router/names'
import { useI18n } from 'petite-vue-i18n'
import QuizNavHeader from '@shared/components/Quiz/QuizNavHeader.vue'

export default {
  name: 'SelfTest',
  components: {
    QuizNavHeader,
  },
  mixins: [selfTestNavigation],
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      media: B2bMedia,
    }
  },
  computed: {
    title() {
      switch (this.$route.name) {
        case ROUTE.ASSESSMENT_SELF_TEST_WELCOME:
          return 'b2b_self_test_welcome'

        case ROUTE.ASSESSMENT_SELF_TEST_PRECAUTIONS_COPD:
          return 'b2b_self_test_red_flags_question_title'

        default:
          return 'b2b_self_test_generic_assessment'
      }
    },
    backButton() {
      return this.$route.name !== ROUTE.ASSESSMENT_SELF_TEST_WELCOME
    },
    infoText() {
      switch (this.$route.name) {
        case ROUTE.ASSESSMENT_SELF_TEST_PRECAUTIONS_MSK:
          return this.t('b2b__red_flags__modal_content')

        default:
          return ''
      }
    },
    cssClasses() {
      const res = ['self-test-layout']
      switch (this.$route.name) {
        case ROUTE.ASSESSMENT_SELF_TEST_WELCOME:
          res.push('self-test-layout--no-progress-bar')
          break

        default:
          break
      }

      return res
    },
  },
}
</script>
<style lang="scss">
.self-test-layout {
  height: 100%;

  .quiz-nav-header-wrap {
    position: absolute;
    left: 0;
    right: 0;

    .quiz-nav-header__title {
      margin: 0 auto;
      text-align: center;
    }
  }

  &--no-progress-bar .quiz-nav-progress {
    display: none;
  }
}
</style>
