<template>
  <ResponsiveLayout>
    <div class="kds-main-wrapper">
      <div class="kds-content-block">
        <p
          class="kds-typography-display-small"
          v-html="$t('b2b__partner_search__intro__header')"
        />
        <p
          class="kds-typography-paragraph-large"
          v-html="$t('b2b__partner_search__intro__description')"
        />
      </div>

      <PartnerSearchCtrl
        :pending-results="pendingResults"
        :corporate-key="corporateKey"
        :loading="loadingSearchResults"
        @search="handleSearch"
        @ctrl-state-change="handlePartnerSearchCtrlState"
      />

      <BaseBanner
        v-if="showNotFoundWarningBox"
        variant="important"
        :title="$t('b2b__partner_search__not_found_warning__header')"
      >
        <p v-html="$t('b2b__partner_search__not_found_warning__description')" />
      </BaseBanner>
    </div>
  </ResponsiveLayout>
</template>

<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { debounce } from '@shared/utils'

import { searchCorporate } from '@shared/api-endpoints'

import ROUTE from '@us/app/router/appModule/names'

import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'
import PartnerSearchCtrl from '@us/app/components/PartnerSearch/PartnerSearchCtrl.vue'
import BaseBanner from '@shared/components/BaseBanner.vue'
import { CanceledError } from 'axios'

const router = useRouter()
const route = useRoute()

const loadingSearchResults = ref(false)
const searchResults = ref([])
const pendingResults = ref(null)
const corporateKey = ref(route.params?.corporate_key || '')
const showNotFoundWarningBox = ref(false)
const ctrlItemDisabled = ref(false)

let searchRequestAbortController

const debouncedSearchCorporate = debounce(async (search, token, callback) => {
  try {
    const result = await searchCorporate(search, '', token)
    callback(result.data.corporates)
  } catch (error) {
    if (error instanceof CanceledError) {
      // expected
    } else {
      throw error
    }
  }
}, 250)

const handleSearch = (searchTerm) => {
  const cleanedSearchTerm = searchTerm.trim()
  if (!cleanedSearchTerm || cleanedSearchTerm.length <= 2) return

  loadingSearchResults.value = false
  searchResults.value = []

  if (searchRequestAbortController) {
    searchRequestAbortController.abort(
      'Cancel corporate search as newer search request is fired',
    )
  }

  searchRequestAbortController = new AbortController()

  loadingSearchResults.value = true

  debouncedSearchCorporate(
    cleanedSearchTerm,
    searchRequestAbortController.signal,
    (corporates) => {
      pendingResults.value = corporates
      loadingSearchResults.value = false
    },
  )
}

const handlePartnerSelectedEvent = (partner) => {
  router.push({
    name: ROUTE.B2B_SELECT_VERIFICATION_METHOD,
    params: {
      corporate_key: partner.key,
    },
  })
}

const handlePartnerSearchCtrlState = ({ state, value }) => {
  ctrlItemDisabled.value = false

  switch (state) {
    case 'active':
      showNotFoundWarningBox.value = false
      break

    case 'disabled':
      showNotFoundWarningBox.value = true
      break

    case 'processing':
      ctrlItemDisabled.value = true
      break

    case 'validated':
      ctrlItemDisabled.value = true
      handlePartnerSelectedEvent(value)
      break

    default:
      break
  }
}
</script>
